import React, { useState } from "react";
import moment from "moment";
import "./HealthRecordCollapse.scss";
import {
  Collapse,
  Icon,
  Avatar,
  Row,
  Col,
  Divider,
  Input,
  Tooltip,
  List,
} from "antd";

const { Panel } = Collapse;
const { TextArea } = Input;
const HealthRecordCollapse = ({ Healthprofile, TabKeyValue }) => {
  const HealthRecords = Healthprofile?.[TabKeyValue];
  console.log("Healthprofiledata", HealthRecords);
  const [activekey, setActivekey] = useState();
  const typedate = (item) => {
    switch (item.toLowerCase()) {
      case "consultations":
        return "Consultation";
      case "surgeries":
        return "Surgery";
      case "diagnosis":
        return "Diagnosis";
      case "vaccination":
        return "Vaccination";
      case "clinicalnotes":
        return "Clinical Notes";
      case "insurances":
        return "Insurances";
      default:
        break;
    }
  };
  const DATE = (TabKeyValue, item) => {
    switch (TabKeyValue) {
      case "consultations":
        return item.reportDate;
      case "surgeries":
        return item.surgeryDate;
      case "diagnosis":
        return item.testDate;
      case "vaccination":
        return item.vaccinDate;
      case "clinicalnotes":
        return item.dateOfRecord;
      case "insurances":
        return item.issuedDate;
      default:
        break;
    }
  };

  const PatientHealthRecords = (item) => {
    return (
      <div className="Patient-HealthRecords">
        <Avatar>{item.patientName?.substr(0, 1)}</Avatar>
        <div className="PatientInfo">
          <div>
            <h3 style={{ opacity: "0.5" }}>Patient name</h3>
            <h3>
              <Tooltip
                overlayClassName="DashboardHealthRecord"
                title={item?.patientName}
              >
                <span>
                  {item?.patientName?.toString().slice(0, 10)}{" "}
                  {item?.patientName?.toString().length >= 11 && "..."}{" "}
                </span>
              </Tooltip>
            </h3>
          </div>
          <div>
            <h3 style={{ opacity: "0.5" }}>Date of {typedate(TabKeyValue)}</h3>
            <h3>{moment(DATE(TabKeyValue, item)).format("MM/DD/YYYY")}</h3>
          </div>
          {item.mode && (
            <>
              <div>
                <h3 style={{ opacity: "0.5" }}>Mode</h3>
                <h3>
                  {/* {item.mode}  */}
                  <Tooltip
                    overlayClassName="DashboardHealthRecord"
                    title={item?.mode}
                  >
                    <span>
                      {item?.mode?.toString().slice(0, 7)}
                      {item?.mode?.toString().length > 7 && "..."}
                    </span>
                  </Tooltip>
                </h3>
              </div>
              <div>
                <h3 style={{ opacity: "0.5" }}>Issue/reason</h3>
                <h3>
                  <Tooltip
                    overlayClassName="DashboardHealthRecord"
                    title={item?.issue}
                  >
                    <span>
                      {item?.issue?.toString().slice(0, 10)}{" "}
                      {item?.issue?.toString().length >= 11 && "..."}{" "}
                    </span>
                  </Tooltip>
                </h3>
              </div>
            </>
          )}
          {item?.type === "insurances" && (
            <>
              <div>
                <h3 style={{ opacity: "0.5" }}> Policy Id</h3>
                {/* <h3>{item.polocyId}</h3> */}
                <h3>
                  <Tooltip
                    overlayClassName="DashboardHealthRecord"
                    title={item?.polocyId}
                  >
                    <span>
                      {item?.polocyId?.slice(0, 5)}
                      {item?.polocyId?.length >= 5 && "..."}
                    </span>
                  </Tooltip>
                </h3>
              </div>
              {/* <div>
                <h3 style={{ opacity: "0.5" }}> Issue Date</h3>
                <h3>{moment(item.issuedDate).format("MM/DD/YYYY")}</h3>

              </div> */}
              <div>
                <h3 style={{ opacity: "0.5" }}> Insurance Name</h3>
                {/* <h3>{item.insuranceName}</h3> */}
                <h3>
                  {item?.insuranceName ? (
                    <Tooltip
                      overlayClassName="DashboardHealthRecord"
                      title={item?.insuranceName}
                    >
                      <span>
                        {item?.insuranceName?.slice(0, 10)}
                        {item?.insuranceName?.length >= 11 && "..."}
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      overlayClassName="DashboardHealthRecord"
                      title={"No Insurance Name while filling the Data"}
                    >
                      <span>No name...</span>
                    </Tooltip>
                  )}
                </h3>
              </div>
            </>
          )}
          {item.doctorName && (
            <>
              <div>
                <h3 style={{ opacity: "0.5" }}>Doctor Name</h3>
                {/* <h3>{item.doctorName}</h3> */}
                <h3>
                  <Tooltip
                    overlayClassName="DashboardHealthRecord"
                    title={item?.doctorName}
                  >
                    <span>
                      {item?.doctorName?.slice(0, 10)}
                      {item?.doctorName?.length >= 11 && "..."}
                    </span>
                  </Tooltip>
                </h3>
              </div>
              {/* <div>
                <h3 style={{ opacity: "0.5" }}>Issue/reason</h3>
                <h3>{item.surgeryTitle}</h3>
              </div> */}
              <div>
                {item?.type === "clinicalnotes" ? (
                  <h3 style={{ opacity: "0.5" }}>Hospital Name</h3>
                ) : item?.type === "surgeries" ? (
                  <h3 style={{ opacity: "0.5" }}>{"Surgery Name"}</h3>
                ) : (
                  <h3 style={{ opacity: "0.5" }}>Issue/reason</h3>
                )}
                {item?.type === "clinicalnotes" && TabKeyValue === "All" ? (
                  <h3>
                    <Tooltip
                      overlayClassName="DashboardHealthRecord"
                      title={item?.hospitalName}
                    >
                      <span>
                        {item?.hospitalName?.slice(0, 10)}
                        {item?.hospitalName?.length >= 11 && "..."}
                      </span>
                    </Tooltip>
                  </h3>
                ) : (
                  <h3>
                    <Tooltip
                      overlayClassName="DashboardHealthRecord"
                      title={item?.surgeryTitle}
                    >
                      <span>
                        {item?.surgeryTitle?.slice(0, 10)}
                        {item?.surgeryTitle?.length >= 11 && "..."}
                      </span>
                    </Tooltip>
                  </h3>
                )}

                <h3
                  style={{
                    textAlign: "left",
                  }}
                >
                  {TabKeyValue === "clinicalnotes" && (
                    <Tooltip
                      overlayClassName="DashboardHealthRecord"
                      title={item?.hospitalName}
                    >
                      <span>
                        {item?.hospitalName?.slice(0, 10)}

                        {item?.hospitalName?.length >= 11 && "..."}
                      </span>
                    </Tooltip>
                  )}
                </h3>
              </div>
            </>
          )}
          {TabKeyValue === "diagnosis" && (
            <>
              {/* <div>
                <h3 style={{ opacity: "0.5" }}>Center Name</h3>
                <h3>{item.diagonisticCenterName}</h3>
              </div>
              <div>
                <h3 style={{ opacity: "0.5" }}>Issue/reason</h3>
                <h3>{"Diabetes"}</h3>
              </div> */}
              <div>
                <h3 style={{ opacity: "0.5" }}>Center Name</h3>
                {/* <h3>{item.diagonisticCenterName} </h3> */}
                <h3>
                  <Tooltip
                    overlayClassName="DashboardHealthRecord"
                    title={item?.diagonisticCenterName}
                  >
                    <span>
                      {item?.diagonisticCenterName?.slice(0, 10)}
                      {item?.diagonisticCenterName?.length >= 11 && "..."}
                    </span>
                  </Tooltip>
                </h3>
              </div>
              <div>
                <h3 style={{ opacity: "0.5" }}>Issue/reason</h3>
                {/* <h3> {item?.issue} </h3> */}
                <h3>
                  <Tooltip
                    overlayClassName="DashboardHealthRecord"
                    title={item?.issue}
                  >
                    <span>
                      {item?.issue?.slice(0, 10)}
                      {item?.issue?.length >= 11 && "..."}
                    </span>
                  </Tooltip>
                </h3>
              </div>
            </>
          )}
          {/* <>
              <div>
                <h3 style={{ opacity: "0.5" }}>Vaccine Name</h3>
                <h3>{item.vaccineName}</h3>
              </div>
              <div>
                <h3 style={{ opacity: "0.5" }}>Vaccination By</h3>
                <h3>{item.vaccinationBy}</h3>
              </div>
            </> */}
          {TabKeyValue === "vaccination" && (
            <>
              <div>
                <h3 style={{ opacity: "0.5" }}> Vaccine Name</h3>
                {/* <h3>{item.vaccineName}</h3> */}
                <h3>
                  <Tooltip
                    overlayClassName="DashboardHealthRecord"
                    title={item.vaccineName}
                  >
                    <span>
                      {item?.vaccineName?.slice(0, 10)}

                      {item?.vaccineName?.length >= 11 && "..."}
                    </span>
                  </Tooltip>
                </h3>
              </div>
              <div>
                <h3 style={{ opacity: "0.5" }}>Vaccination By</h3>
                {/* <h3>{item.vaccinationBy}</h3> */}
                <h3>
                  <Tooltip
                    overlayClassName="DashboardHealthRecord"
                    title={item.vaccinationBy}
                  >
                    <span>
                      {item?.vaccinationBy?.slice(0, 10)}
                      {item?.vaccinationBy?.length >= 11 && "..."}
                    </span>
                  </Tooltip>
                </h3>
              </div>
            </>
          )}
          <div>
            <h3 style={{ opacity: "0.5" }}>More Information</h3>
            <h3 className="MORE-INFO">
              {/* {item.mode === "Tele Health" && (
                <>
                  <Icon type="clock-circle" theme="filled" /> 30 min &nbsp;
                </>
              )} */}
              <Icon type="paper-clip" />
              {item?.reports?.length}
              {item?.certificate?.length && 1}
              {item?.documents?.length}
              {item?.testReport?.length}
              &nbsp;
              <Icon type="file-text" theme="filled" /> <Icon type="check" />
            </h3>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="PatientHealthRecords-Collapse">
      <div className="PatientHealthRecords-Collapse">
        {/* <Collapse accordion> */}
        <List
          dataSource={HealthRecords ?? []}
          pagination={{ pageSize: 4, size: "small" }}
          renderItem={(item, i) => (
            <>
              <Collapse
                activeKey={activekey?.at(-1)}
                onChange={(i) => setActivekey(i)}
                destroyInactivePanel
              >
                <Panel header={PatientHealthRecords(item)} key={i}>
                  <div className="PatientHealthRecords-Collapse-Details">
                    <Divider />
                    <Row>
                      <Col lg={12}>
                        {item.surgeryType && (
                          <>
                            <h6>
                              <p> Hospital Name</p> <span>:</span>
                              {/* <span>{item.hospitalName}</span> */}
                              <span>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={item?.hospitalName}
                                >
                                  <span>
                                    {item?.hospitalName?.slice(0, 10)}{" "}
                                    {item?.hospitalName?.length >= 11 && "..."}
                                  </span>
                                </Tooltip>
                              </span>
                            </h6>
                            <h6>
                              <p>Surgery Type</p> <span>:</span>
                              {/* <span>{item.surgeryType}</span> */}
                              <span>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={item?.surgeryType}
                                >
                                  <span>
                                    {item?.surgeryType?.slice(0, 10)}{" "}
                                    {item?.surgeryType?.length >= 11 && "..."}
                                  </span>
                                </Tooltip>
                              </span>
                            </h6>
                          </>
                        )}
                        <h6>
                          <p>Date of Created </p> <span>:</span>
                          {/* <span>{item.refferedDoctor}</span> */}
                          <span>
                            {moment(item.created_at).format("MM/DD/YYYY")}
                          </span>
                        </h6>
                        {item?.type === "insurances" && (
                          <>
                            <h6>
                              <p>Issue Date</p> <span>:</span>
                              {/* <span>{item.refferedDoctor}</span> */}
                              <span>
                                {moment(item.issuedDate).format("MM/DD/YYYY")}
                              </span>
                            </h6>
                          </>
                        )}
                        {item.refferedDoctor && (
                          <>
                            <h6>
                              <p>Reffered Doctor</p> <span>:</span>
                              {/* <span>{item.refferedDoctor}</span> */}
                              <span>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={item?.refferedDoctor}
                                >
                                  <span>
                                    {item?.refferedDoctor?.slice(0, 10)}{" "}
                                    {item?.refferedDoctor?.length >= 11 &&
                                      "..."}
                                  </span>
                                </Tooltip>
                              </span>
                            </h6>
                            <h6>
                              <p>Test Type</p> <span>:</span>
                              {/* <span>{item.testType}</span> */}
                              <span>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={item?.testType}
                                >
                                  <span>
                                    {item?.testType?.slice(0, 10)}{" "}
                                    {item?.testType?.length >= 11 && "..."}{" "}
                                  </span>
                                </Tooltip>
                              </span>
                            </h6>
                          </>
                        )}
                        {item.refId && (
                          <>
                            <h6>
                              <p>Age</p> <span>:</span>
                              <span>{item.age}</span>
                            </h6>
                            <h6>
                              <p>Batch No</p> <span>:</span>
                              {/* <span>{item.batchNo}</span> */}
                              <span>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={item?.batchNo}
                                >
                                  <span>
                                    {item?.batchNo?.slice(0, 10)}{" "}
                                    {item?.batchNo?.length >= 11 && "..."}{" "}
                                  </span>
                                </Tooltip>
                              </span>
                            </h6>
                            <h6>
                              <p>Id Verified</p> <span>:</span>
                              {/* <span>{item?.idVerified}</span> */}
                              <span>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={item?.idVerified}
                                >
                                  <span>
                                    {item?.idVerified?.slice(0, 10)}{" "}
                                    {item?.idVerified?.length >= 11 && "..."}
                                  </span>
                                </Tooltip>
                              </span>
                            </h6>
                            <h6>
                              <p>Ref ID</p> <span>:</span>
                              {/* <span>{item.refId}</span> */}
                              <span>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={item?.refId}
                                >
                                  <span>
                                    {item?.refId?.slice(0, 10)}{" "}
                                    {item?.refId?.length >= 11 && "..."}
                                  </span>
                                </Tooltip>
                              </span>
                            </h6>
                            <h6>
                              <p>Vaccination At</p> <span>:</span>
                              {/* <span>{item?.vaccinationAt}</span> */}
                              <span>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={item?.vaccinationAt}
                                >
                                  <span>
                                    {item?.vaccinationAt?.slice(0, 10)}{" "}
                                    {item?.vaccinationAt?.length >= 11 && "..."}
                                  </span>
                                </Tooltip>
                              </span>
                            </h6>
                          </>
                        )}

                        {item?.type !== "insurances"
                          ? item?.type !== "consultations"
                            ? item?.type !== "Consultations" && (
                                <>
                                  <h3> Notes </h3>
                                  <h4>
                                    <Tooltip
                                      overlayClassName="DashboardHealthRecord"
                                      title={item.notes}
                                    >
                                      <span>
                                        {item?.notes?.slice(0, 10)}{" "}
                                        {item?.notes?.length >= 11 && "..."}{" "}
                                      </span>
                                    </Tooltip>
                                  </h4>
                                </>
                              )
                            : ""
                          : ""}
                      </Col>
                      <Col lg={12}>
                        <h3> Files & Screenshots </h3>
                        {item.reports &&
                          item?.reports?.map((url, i) => (
                            <div className="Files">
                              <div className="FileContent">
                                <Avatar
                                  icon={
                                    <Icon type="file-text" theme="filled" />
                                  }
                                />
                                {/* <h3>{url.reportType}</h3> */}
                                <h3>
                                  <Tooltip
                                    overlayClassName="DashboardHealthRecord"
                                    title={url?.substring(
                                      url?.lastIndexOf("/") + 1
                                    )}
                                  >
                                    <span>
                                      {url
                                        ?.substring(url?.lastIndexOf("/") + 1)
                                        ?.slice(0, 10)}{" "}
                                      {url?.substring(url?.lastIndexOf("/") + 1)
                                        ?.length >= 11 && "..."}
                                    </span>
                                  </Tooltip>
                                </h3>
                              </div>
                              <a
                                href={process.env.PUBLIC_URL + url}
                                target="_blank"
                              >
                                <Icon type="download" />
                              </a>
                            </div>
                          ))}

                        {item?.certificate && (
                          <div className="Files">
                            <div className="FileContent">
                              <Avatar
                                icon={<Icon type="file-text" theme="filled" />}
                              />
                              <h3>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={item?.certificate?.substring(
                                    item?.certificate?.lastIndexOf("/") + 1
                                  )}
                                >
                                  <span>
                                    {item?.certificate
                                      ?.substring(
                                        item?.certificate?.lastIndexOf("/") + 1
                                      )
                                      ?.slice(0, 10)}{" "}
                                    {item?.certificate?.substring(
                                      item?.certificate?.lastIndexOf("/") + 1
                                    )?.length >= 11 && "..."}
                                  </span>
                                </Tooltip>
                              </h3>
                            </div>
                            <a
                              href={process.env.PUBLIC_URL + item?.certificate}
                              target="_blank"
                            >
                              <Icon type="download" />
                            </a>
                          </div>
                        )}
                        {item?.documents &&
                          item?.documents?.map((url, i) => (
                            <>
                              <div className="Files">
                                <div className="FileContent">
                                  <Avatar
                                    icon={
                                      <Icon type="file-text" theme="filled" />
                                    }
                                  />
                                  <h3>
                                    <Tooltip
                                      overlayClassName="DashboardHealthRecord"
                                      title={url?.substring(
                                        url?.lastIndexOf("/") + 1
                                      )}
                                    >
                                      <span>
                                        {url
                                          ?.substring(url?.lastIndexOf("/") + 1)
                                          ?.slice(0, 10)}{" "}
                                        {url?.substring(
                                          url?.lastIndexOf("/") + 1
                                        )?.length >= 11 && "..."}
                                      </span>
                                    </Tooltip>
                                  </h3>
                                </div>
                                <a
                                  href={process.env.PUBLIC_URL + url}
                                  target="_blank"
                                >
                                  <Icon type="download" />
                                </a>
                              </div>
                            </>
                          ))}
                        {item?.testReport &&
                          item?.testReport?.map((url, i) => (
                            <>
                              <div className="Files">
                                <div className="FileContent">
                                  <Avatar
                                    icon={
                                      <Icon type="file-text" theme="filled" />
                                    }
                                  />
                                  <h3>
                                    <Tooltip
                                      overlayClassName="DashboardHealthRecord"
                                      title={url?.substring(
                                        url?.lastIndexOf("/") + 1
                                      )}
                                    >
                                      <span>
                                        {url
                                          ?.substring(url?.lastIndexOf("/") + 1)
                                          ?.slice(0, 10)}{" "}
                                        {url?.substring(
                                          url?.lastIndexOf("/") + 1
                                        )?.length >= 11 && "..."}
                                      </span>
                                    </Tooltip>
                                  </h3>
                                </div>
                                <a
                                  href={process.env.PUBLIC_URL + url}
                                  target="_blank"
                                >
                                  <Icon type="download" />
                                </a>
                              </div>
                            </>
                          ))}
                      </Col>
                    </Row>
                  </div>
                </Panel>
              </Collapse>
            </>
          )}
        />
        {/* </Collapse> */}
      </div>

      <div className="PatientHealthRecords-mobile">
        {/* {HealthRecords?.map((item, i) => ( */}
        <List
          dataSource={HealthRecords ?? []}
          pagination={{ pageSize: 4, size: "small" }}
          renderItem={(item, i) => (
            <>
              <Collapse
                activeKey={activekey?.at(-1)}
                onChange={(i) => setActivekey(i)}
                destroyInactivePanel
              >
                <div className="PatientHealthRecords-setup">
                  <div className="PatientInfo">
                    <div style={{ display: "flex" }}>
                      <Avatar>{item.patientName?.substr(0, 1)}</Avatar>
                      <h3>{item?.patientName}</h3>
                    </div>
                    <div>
                      {item.mode === "Tele Health" && (
                        <>
                          <Icon type="clock-circle" theme="filled" /> 30 min
                          &nbsp;
                        </>
                      )}
                      <Icon type="paper-clip" /> {item.reports?.length} &nbsp;
                      <Icon type="file-text" theme="filled" />{" "}
                      <Icon type="check" />
                    </div>
                  </div>
                  <br />
                  {item?.type !== "insurances" && (
                    <div className="profile__mode__info">
                      <h3 style={{ opacity: "0.5" }}>Date of Created</h3>
                      <h3> {moment(item.created_at).format("MM/DD/YYYY")} </h3>
                    </div>
                  )}
                  {item.mode && (
                    <>
                      {/* <div className="profile__mode__info">
                        <h3 style={{ opacity: "0.5" }}>
                          Date of {typedate(item.type)}
                        </h3>
                        <h3>{moment(DATE(item)).format("MM/DD/YYYY")}</h3>
                      </div> */}
                      <div className="profile__mode__info">
                        <h3 style={{ opacity: "0.5" }}>Mode</h3>
                        <h3>{item.mode}</h3>
                      </div>
                      <div className="profile__mode__info">
                        <h3 style={{ opacity: "0.5" }}>Issue/reason</h3>
                        {/* <h3>{item.issue}</h3> */}
                        <h3>
                          {" "}
                          <Tooltip
                            overlayClassName="DashboardHealthRecord"
                            title={item?.issue}
                          >
                            <span>
                              {item?.issue?.slice(0, 10)}
                              {item?.issue?.length >= 11 && "..."}
                            </span>
                          </Tooltip>
                        </h3>
                      </div>
                    </>
                  )}

                  {/*  added these line just for temporary  start here         issuedDate polocyId endDate */}
                  {TabKeyValue === "insurances" && (
                    <>
                      <div className="insurance-fileds-mobile">
                        <h3 style={{ opacity: "0.5" }}> Date of Created</h3>
                        {/* <h3>{item.polocyId}</h3> */}
                        <h3>{moment(item.created_at).format("MM/DD/YYYY")}</h3>
                      </div>
                      <div className="insurance-fileds-mobile">
                        <h3 style={{ opacity: "0.5" }}>Patient Name</h3>
                        {/* <h3>{item.polocyId}</h3> */}
                        <h3>
                          <Tooltip
                            overlayClassName="DashboardHealthRecord"
                            title={item?.patientName}
                          >
                            <span>
                              {item?.patientName?.slice(0, 10)}
                              {item?.patientName?.length >= 11 && "..."}
                            </span>
                          </Tooltip>
                        </h3>
                      </div>
                      <div className="insurance-fileds-mobile">
                        <h3 style={{ opacity: "0.5" }}>
                          Date of {typedate(item.type)}
                        </h3>
                        <h3>{moment(DATE(item)).format("MM/DD/YYYY")}</h3>
                      </div>
                      <div className="insurance-fileds-mobile">
                        <h3 style={{ opacity: "0.5" }}>polocy Id</h3>
                        {/* <h3>{item.polocyId}</h3> */}
                        <h3>
                          <Tooltip
                            overlayClassName="DashboardHealthRecord"
                            title={item?.polocyId}
                          >
                            <span>
                              {item?.polocyId?.slice(0, 10)}
                              {item?.polocyId?.length >= 11 && "..."}
                            </span>
                          </Tooltip>
                        </h3>
                      </div>
                      <div className="insurance-fileds-mobile">
                        <h3 style={{ opacity: "0.5" }}> issued Date </h3>
                        <h3>{moment(item.issuedDate).format("MM/DD/YYYY")}</h3>

                        {/* <h3>{item.type}</h3> */}
                      </div>
                      <div className="insurance-fileds-mobile">
                        <h3 style={{ opacity: "0.5" }}> insurance Name</h3>
                        <h3>{item.insuranceName}</h3>
                      </div>
                    </>
                  )}

                  {/*  added these line just for temporary  ends here*/}
                  {item.doctorName && (
                    <>
                      <div className="profile__mode__info">
                        <h3 style={{ opacity: "0.5" }}>
                          Date of {typedate(item.type)}
                        </h3>
                        <h3>{moment(DATE(item)).format("MM/DD/YYYY")}</h3>
                      </div>
                      <div className="profile__mode__info">
                        <h3 style={{ opacity: "0.5" }}>Doctor Name</h3>
                        {/* <h3>{item.doctorName}</h3> */}
                        <h3>
                          <Tooltip
                            overlayClassName="DashboardHealthRecord"
                            title={item?.doctorName}
                          >
                            <span>
                              {item?.doctorName?.slice(0, 10)}
                              {item?.doctorName?.length >= 11 && "..."}
                            </span>
                          </Tooltip>
                        </h3>
                      </div>
                      <div className="profile__mode__info">
                        {TabKeyValue === "clinicalnotes" ? (
                          <h3 style={{ opacity: "0.5" }}>Hospital Name</h3>
                        ) : TabKeyValue === "surgeries" ? (
                          <h3 style={{ opacity: "0.5" }}>{"Surgery Name"}</h3>
                        ) : (
                          <h3 style={{ opacity: "0.5" }}>Issue/reason</h3>
                        )}
                        {/* <h3>{item?.surgeryTitle}</h3> */}

                        {/*  added these line just for temporary */}

                        {TabKeyValue === "clinicalnotes" ? (
                          <h3
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {" "}
                            <Tooltip
                              overlayClassName="DashboardHealthRecord"
                              title={item?.hospitalName}
                            >
                              <span>
                                {item?.hospitalName?.slice(0, 10)}

                                {item?.hospitalName?.length >= 11 && "..."}
                              </span>
                            </Tooltip>{" "}
                          </h3>
                        ) : (
                          <h3>
                            <Tooltip
                              overlayClassName="DashboardHealthRecord"
                              title={item?.surgeryTitle}
                            >
                              <span>
                                {item?.surgeryTitle?.slice(0, 10)}
                                {item?.surgeryTitle?.length >= 11 && "..."}
                              </span>
                            </Tooltip>
                          </h3>
                        )}
                      </div>
                    </>
                  )}
                  {item.diagonisticCenterName && (
                    <>
                      <div className="profile__mode__info">
                        <h3 style={{ opacity: "0.5" }}>
                          Date of {typedate(item.type)}
                        </h3>
                        <h3>{moment(DATE(item)).format("MM/DD/YYYY")}</h3>
                      </div>
                      <div className="profile__mode__info">
                        <h3 style={{ opacity: "0.5" }}>Center Name</h3>
                        {/* <h3>{item.diagonisticCenterName}</h3> */}
                        <h3>
                          <Tooltip
                            overlayClassName="DashboardHealthRecord"
                            title={item?.diagonisticCenterName}
                          >
                            <span>
                              {item?.diagonisticCenterName?.slice(0, 10)}
                              {item?.diagonisticCenterName?.length >= 11 &&
                                "..."}
                            </span>
                          </Tooltip>
                        </h3>
                      </div>
                      <div className="profile__mode__info">
                        <h3 style={{ opacity: "0.5" }}>Issue/reason</h3>
                        {/* <h3>{item?.issue} </h3> */}
                        <h3>
                          <Tooltip
                            overlayClassName="DashboardHealthRecord"
                            title={item?.issue}
                          >
                            <span>
                              {item?.issue?.slice(0, 10)}
                              {item?.issue?.length >= 11 && "..."}
                            </span>
                          </Tooltip>
                        </h3>
                      </div>
                    </>
                  )}
                  {item.vaccinationBy && (
                    <>
                      <div className="profile__mode__info">
                        <h3 style={{ opacity: "0.5" }}>
                          Date of {typedate(item.type)}
                        </h3>
                        <h3>{moment(DATE(item)).format("MM/DD/YYYY")}</h3>
                      </div>
                      <div className="profile__mode__info">
                        <h3 style={{ opacity: "0.5" }}>Vaccine Name</h3>
                        {/* <h3>{item.vaccineName}</h3> */}
                        <h3>
                          <Tooltip
                            overlayClassName="DashboardHealthRecord"
                            title={item.vaccineName}
                          >
                            <span>
                              {item?.vaccineName?.slice(0, 10)}

                              {item?.vaccineName?.length >= 11 && "..."}
                            </span>
                          </Tooltip>
                        </h3>
                      </div>
                      <div className="profile__mode__info">
                        <h3 style={{ opacity: "0.5" }}>Vaccination By</h3>
                        {/* <h3>{item.vaccinationBy}</h3> */}
                        <h3>
                          <Tooltip
                            overlayClassName="DashboardHealthRecord"
                            title={item.vaccinationBy}
                          >
                            <span>
                              {item?.vaccinationBy?.slice(0, 10)}
                              {item?.vaccinationBy?.length >= 11 && "..."}
                            </span>
                          </Tooltip>
                        </h3>
                      </div>
                    </>
                  )}
                  {item.refferedDoctor && (
                    <>
                      <div className="profile__mode__info_data">
                        <h6>
                          <p>Reffered Doctor</p> <span>:</span>
                          {/* <span>{item.refferedDoctor}</span> */}
                          <span>
                            <Tooltip
                              overlayClassName="DashboardHealthRecord"
                              title={item?.refferedDoctor}
                            >
                              <span>
                                {item?.refferedDoctor?.slice(0, 10)}{" "}
                                {item?.refferedDoctor?.length >= 11 && "..."}
                              </span>
                            </Tooltip>
                          </span>
                        </h6>
                        <h6>
                          <p>TestType</p> <span>:</span>
                          {/* <span>{item.testType}</span> */}
                          <span>
                            <Tooltip
                              overlayClassName="DashboardHealthRecord"
                              title={item?.testType}
                            >
                              <span>
                                {item?.testType?.slice(0, 10)}{" "}
                                {item?.testType?.length >= 11 && "..."}{" "}
                              </span>
                            </Tooltip>
                          </span>
                        </h6>
                      </div>
                    </>
                  )}

                  {item.refId && (
                    <>
                      <div className="profile__mode__info_data">
                        <h6>
                          <p>Age</p> <span>:</span>
                          <span>{item.age}</span>
                        </h6>
                        <h6>
                          <p>Batch No</p> <span>:</span>
                          {/* <span>{item.batchNo}</span> */}
                          <span>
                            <Tooltip
                              overlayClassName="DashboardHealthRecord"
                              title={item?.batchNo}
                            >
                              <span>
                                {item?.batchNo?.slice(0, 10)}{" "}
                                {item?.batchNo?.length >= 11 && "..."}{" "}
                              </span>
                            </Tooltip>
                          </span>
                        </h6>
                        <h6>
                          <p> Id Verified</p> <span>:</span>
                          {/* <span>{item?.idVerified}</span> */}
                          <span>
                            <Tooltip
                              overlayClassName="DashboardHealthRecord"
                              title={item?.idVerified}
                            >
                              <span>
                                {item?.idVerified?.slice(0, 10)}{" "}
                                {item?.idVerified?.length >= 11 && "..."}
                              </span>
                            </Tooltip>
                          </span>
                        </h6>
                        <h6>
                          <p>Ref ID</p> <span>:</span>
                          {/* <span>{item.refId}</span> */}
                          <span>
                            <Tooltip
                              overlayClassName="DashboardHealthRecord"
                              title={item?.refId}
                            >
                              <span>
                                {item?.refId?.slice(0, 10)}{" "}
                                {item?.refId?.length >= 11 && "..."}
                              </span>
                            </Tooltip>
                          </span>
                        </h6>
                        <h6>
                          <p>Vaccination At</p> <span>:</span>
                          <span>{item?.vaccinationAt}</span>
                        </h6>
                      </div>
                    </>
                  )}
                  {item?.type !== "insurances"
                    ? item?.type !== "consultations"
                      ? item?.type !== "Consultations" && (
                          <>
                            <h3> Notes </h3>
                            <h4>
                              <Tooltip
                                overlayClassName="DashboardHealthRecord"
                                title={item.notes}
                              >
                                <span>
                                  {item?.notes?.slice(0, 10)}{" "}
                                  {item?.notes?.length >= 11 && "..."}{" "}
                                </span>
                              </Tooltip>
                            </h4>
                          </>
                        )
                      : ""
                    : ""}

                  {/* <h3 className="ReadMore">{t("Read more")}</h3> */}
                  <div className="PatientHealthRecords-Collapse-file">
                    <h3> documnets </h3>
                    {/* {item.reports
                      ? item.reports?.map((item, i) => (
                          <div className="Files">
                            <div className="FileContent">
                              <Avatar
                                icon={<Icon type="file-text" theme="filled" />}
                              />
                              <h3>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={item?.reportType}
                                >
                                  <span>
                                    {item?.reportType?.slice(0, 10)}{" "}
                                    {item?.reportType?.length >= 11 && "..."}
                                  </span>
                                </Tooltip>
                              </h3>
                            </div>
                            <a
                              href={process.env.PUBLIC_URL + item.url}
                              target="_blank"
                            >
                              <Icon type="download" />
                            </a>
                          </div>
                        ))
                      : !item.documents && (
                          <div className="Files">
                            <div className="FileContent">
                              <Avatar
                                icon={<Icon type="file-text" theme="filled" />}
                              />
                              <h3>{t("certificate")}</h3>
                            </div>
                            {item.certificate ? (
                              <a
                                href={process.env.PUBLIC_URL + item.certificate}
                                target="_blank"
                              >
                                <Icon type="download" />
                              </a>
                            ) : (
                              <a
                                href={process.env.PUBLIC_URL + item.testReport}
                                target="_blank"
                              >
                                <Icon type="download" />
                              </a>
                            )}
                          </div>
                        )}
                    {item.documents &&
                      item.documents?.map((item, i) => (
                        <div className="Files">
                          <div className="FileContent">
                            <Avatar
                              icon={<Icon type="file-text" theme="filled" />}
                            />
                            <h3 style={{ display: "flex" }}>
                              {item?.substring(item?.lastIndexOf("/") + 1)}
                            </h3>
                          </div>
                          <a
                            href={process.env.PUBLIC_URL + item}
                            target="_blank"
                          >
                            <Icon type="download" />
                          </a>
                        </div>
                      ))} */}
                    {item.reports &&
                      item?.reports?.map((url, i) => (
                        <div className="Files">
                          <div className="FileContent">
                            <Avatar
                              icon={<Icon type="file-text" theme="filled" />}
                            />
                            {/* <h3>{url.reportType}</h3> */}
                            <h3>
                              <Tooltip
                                overlayClassName="DashboardHealthRecord"
                                title={url?.substring(
                                  url?.lastIndexOf("/") + 1
                                )}
                              >
                                <span>
                                  {url
                                    ?.substring(url?.lastIndexOf("/") + 1)
                                    ?.slice(0, 10)}{" "}
                                  {url?.substring(url?.lastIndexOf("/") + 1)
                                    ?.length >= 11 && "..."}
                                </span>
                              </Tooltip>
                            </h3>
                          </div>
                          <a
                            href={process.env.PUBLIC_URL + url}
                            target="_blank"
                          >
                            <Icon type="download" />
                          </a>
                        </div>
                      ))}

                    {item?.certificate && (
                      <div className="Files">
                        <div className="FileContent">
                          <Avatar
                            icon={<Icon type="file-text" theme="filled" />}
                          />
                          <h3>
                            <Tooltip
                              overlayClassName="DashboardHealthRecord"
                              title={item?.certificate?.substring(
                                item?.certificate?.lastIndexOf("/") + 1
                              )}
                            >
                              <span>
                                {item?.certificate
                                  ?.substring(
                                    item?.certificate?.lastIndexOf("/") + 1
                                  )
                                  ?.slice(0, 10)}{" "}
                                {item?.certificate?.substring(
                                  item?.certificate?.lastIndexOf("/") + 1
                                )?.length >= 11 && "..."}
                              </span>
                            </Tooltip>
                          </h3>
                        </div>
                        <a
                          href={process.env.PUBLIC_URL + item?.certificate}
                          target="_blank"
                        >
                          <Icon type="download" />
                        </a>
                      </div>
                    )}
                    {item?.documents &&
                      item?.documents?.map((url, i) => (
                        <>
                          <div className="Files">
                            <div className="FileContent">
                              <Avatar
                                icon={<Icon type="file-text" theme="filled" />}
                              />
                              <h3>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={url?.substring(
                                    url?.lastIndexOf("/") + 1
                                  )}
                                >
                                  <span>
                                    {url
                                      ?.substring(url?.lastIndexOf("/") + 1)
                                      ?.slice(0, 10)}{" "}
                                    {url?.substring(url?.lastIndexOf("/") + 1)
                                      ?.length >= 11 && "..."}
                                  </span>
                                </Tooltip>
                              </h3>
                            </div>
                            <a
                              href={process.env.PUBLIC_URL + url}
                              target="_blank"
                            >
                              <Icon type="download" />
                            </a>
                          </div>
                        </>
                      ))}
                    {item?.testReport &&
                      item?.testReport?.map((url, i) => (
                        <>
                          <div className="Files">
                            <div className="FileContent">
                              <Avatar
                                icon={<Icon type="file-text" theme="filled" />}
                              />
                              <h3>
                                <Tooltip
                                  overlayClassName="DashboardHealthRecord"
                                  title={url?.substring(
                                    url?.lastIndexOf("/") + 1
                                  )}
                                >
                                  <span>
                                    {url
                                      ?.substring(url?.lastIndexOf("/") + 1)
                                      ?.slice(0, 10)}{" "}
                                    {url?.substring(url?.lastIndexOf("/") + 1)
                                      ?.length >= 11 && "..."}
                                  </span>
                                </Tooltip>
                              </h3>
                            </div>
                            <a
                              href={process.env.PUBLIC_URL + url}
                              target="_blank"
                            >
                              <Icon type="download" />
                            </a>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </Collapse>
            </>
          )}
        />
        {/* ))} */}
      </div>
    </div>
  );
};

export default HealthRecordCollapse;
