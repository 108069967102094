import { combineReducers } from "redux";
import { Doctor_SignupReducer } from "./Sigup/SigupReducer";
import { Login_Reducer } from "./Login/LoginReducer";
import { ProfileReducer } from "./Profile/ProfileReducer";
import { Get_Appointment_Reducer, Update_Appointments_Reducer, Get_Schedule_Reducer } from "./CreateAppintment/CreateAppointmentReducer";
import { AppointmentsReducer, AppointmentsCountReducer, TodaysAppointmentsReducer,ConsultationsReducer } from "./Appointments/AppointmentsReducer";
import { PatientListReducer, PatientList_bookings_Reducer } from "./PatientList/PatientListReducer";
import { PaymentListReducer } from "./Payments/PaymentReducer";

const rootReducer = combineReducers({
  Doctor_SignupReducer,
  Login_Reducer,
  ProfileReducer,
  Get_Appointment_Reducer,TodaysAppointmentsReducer,
  ConsultationsReducer,
  AppointmentsReducer, AppointmentsCountReducer,
  PatientListReducer, PaymentListReducer,
  PatientList_bookings: PatientList_bookings_Reducer,
  Update_Appointments_Reducer, Get_Schedule_Reducer
});

export default rootReducer;
