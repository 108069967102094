import { Fetch_Payments_Request } from "./PaymentActionTypes";


export const PaymentListReducer = (state = {}, action) => {
  switch (action.type) {
    case Fetch_Payments_Request:
      return {...state,...action.payload};
    default:
      return state;
  }
};