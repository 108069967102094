import React from 'react'
import moment from 'moment'
const ViewReview = ({viewReviews}) => {
    console.log( 'viewReviews', viewReviews)

    const ReviewContent = ({title, value}) =>(
        <div>
            <h5>{title}</h5>
            <h4>{value}</h4>
        </div>
    )


    
  return (
    <div className='view_reviews_content'>
              <ReviewContent title="patient Name"  value={viewReviews?.patientName}/>
              <ReviewContent title="patient Email"  value={viewReviews?.patientEmail}/>
              <ReviewContent title="Rating"  value={viewReviews?.rating}/>
              <ReviewContent title="Reviewed date"  value={moment(viewReviews?.created_at).format("MMM-DD-YYYY")}/>
              <ReviewContent title="Comment"  value={viewReviews?.review}/>
              <ReviewContent title="Status"  value={viewReviews?.isApproved === false ? "Pending" : "Accepted"}/>
    </div>
  )
}

export default ViewReview
