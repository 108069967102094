import React from "react";

const NotFound = () => {
  return (
    <div style={{ height: "100vh", textAlign: "center", paddingTop: "20vh" }}>
      <img src={require("../../Assets/nocontent.png")} alt="sorryimg" />
    </div>
  );
};

export default NotFound;
