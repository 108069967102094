import { Icon, Input } from "antd";
import React, { useState, useEffect } from "react";
import AutoComplete from "./../../../../../Common/AutoComplete/AutoComplete";

const { TextArea } = Input;

const EditEducation = ({
  Educationdata,
  setEducation,
  AddInternshiptext,
  setAddInternshiptext,
  AddFellowshiptext,
  setAddFellowshiptext,
  setBlurCheck,
  BlurCheck,
  errors,
  validate,
  setEditResponse,
  EditResponse,
  Removedinternship,
  setRemovedinternship,
  RemovedFellowship,
  setRemovedFellowship
}) => {
  const [AddInternshipinput, setAddInternshipinput] = useState();
  const [AddFellowshipinput, setAddFellowshipinput] = useState();
  const [intialInternInput, setintialInternInput] = useState(false);
  const [intialFellowsInput, setintialFellowsInput] = useState(false);
  const { medicalSchool, internship, fellowship } = Educationdata ?? {};

  useEffect(() => {
    if (internship?.length !== 0) {
      setAddInternshipinput(true);
    }
    if (fellowship?.length !== 0) {
      setAddFellowshipinput(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handletextChange = (e) => {
    const { name, value } = e.target;
    if (name === "AddInternshiptext") {
      setAddInternshiptext(value);
    } else if (name === "AddFellowshiptext") setAddFellowshiptext(value);
    else {
      setEducation({
        ...Educationdata,
        [name]: value,
      });
      setEditResponse({
        ...EditResponse,
        [name]: value,
      });
    }
  };

  const handleAddText = (val) => {
    console.log("ssssss");
    if (val === "Internship") {
      if (internship.length > 0) {
        setAddInternshipinput(true);
        if (AddInternshiptext) {
          setAddInternshipinput(false);

          setEducation({
            ...Educationdata,
            internship: [...internship, AddInternshiptext],
          });

          setEditResponse({
            ...EditResponse,
            internship: [...internship, AddInternshiptext],
          });

          setAddInternshiptext();
          setAddInternshipinput(true);
        }
      } else {
        if (AddInternshiptext) {
          console.log("ggg");
          setEducation({
            ...Educationdata,
            internship: [...internship, AddInternshiptext],
          });
          setEditResponse({
            ...EditResponse,
            internship: [...internship, AddInternshiptext],
          });
          setAddInternshiptext();
          setAddInternshipinput(true);
        }
      }
    } else if (val === "Fellowship") {
      if (fellowship.length > 0) {
        setAddFellowshipinput(true);
        if (AddFellowshiptext) {
          setAddFellowshipinput(false);
          setEducation({
            ...Educationdata,
            fellowship: [...fellowship, AddFellowshiptext],
          });
          setEditResponse({
            ...EditResponse,
            fellowship: [...fellowship, AddFellowshiptext],
          });
          setAddFellowshiptext();
          setAddFellowshipinput(true);
        }
      } else {
        if (AddFellowshiptext) {
          setEducation({
            ...Educationdata,
            fellowship: [...fellowship, AddFellowshiptext],
          });
          setEditResponse({
            ...EditResponse,
            fellowship: [...fellowship, AddFellowshiptext],
          });
          setAddFellowshiptext();
          setAddFellowshipinput(true);
        }
      }
    }
  };

  const handleinternshipTextChange = (i, event) => {
    console.log("event.target.value", i, event.target.value);
    let newArr = { ...Educationdata };
    newArr.internship[i] = event.target.value;
    setEducation(newArr);
    const editarray = { ...EditResponse, internship };
    editarray.internship[i] = event.target.value;
    setEditResponse(editarray);
    if (!newArr.internship[i]) {
      setRemovedinternship([...Removedinternship, i]);
    } else {
      const filter = Removedinternship.filter((item) => item !== i);
      setRemovedinternship(filter);
    }
  };
  const handlefellowshipTextChange = (i, event) => {
    console.log("event.target.value", i, event.target.value);
    let newArr = { ...Educationdata };
    newArr.fellowship[i] = event.target.value;

    const editarray = { ...EditResponse, fellowship };

    editarray.fellowship[i] = event.target.value;
    setEditResponse(editarray);
    setEducation(newArr);
    if (!newArr.fellowship[i]) {
      setRemovedFellowship([...RemovedFellowship, i]);
    } else {
      const filter = RemovedFellowship.filter((item) => item !== i);
      setRemovedFellowship(filter);
    }
  };

  const handleRemovedItems = (i, val) => {
    let newArr = { ...Educationdata };
    if (val === "internship") {
      const filter = newArr.internship.filter((item, index) => index !== i);
      setEducation((prevState) => ({
        ...prevState,
        internship: filter,
      }));
      setEditResponse({
        ...EditResponse,
        internship: filter,
      });

      setintialInternInput(true);
    } else if (val === "fellowship") {
      const filter = newArr.fellowship.filter((item, index) => index !== i);
      setEducation((prevState) => ({
        ...prevState,
        fellowship: filter,
      }));
      setEditResponse({
        ...EditResponse,
        fellowship: filter,
      });

      setintialFellowsInput(true);
    }
  };

  return (
    <>
      <div className="Education-Accomplishments-Title">
        <h2>Education Details</h2>
      </div>
      <div className="Education-Accomplishments-Details">
        <h5>Medical School</h5>
        <TextArea
          maxLength="120"
          value={medicalSchool}
          maxlength="240"
          name="medicalSchool"
          autoSize={{ minRows: 2 }}
          onChange={handletextChange}
          onBlur={(event) => {
            console.log("object");
            setBlurCheck({ ...BlurCheck, [event.target.name]: true });
            validate();
          }}
        />
      </div>
      {BlurCheck["medicalSchool"] && errors.medicalSchool && (
        <p className="ERROR">
          {BlurCheck["medicalSchool"] && errors.medicalSchool}
        </p>
      )}
      <div className="Education-Accomplishments-Details">
        <h5>Internship</h5>
        <div className="Inputs1">
          {internship?.length > 0
            ? internship.map((item, i) => (
                <div className="Inner-Field-Details">
                  <TextArea
                    maxLength="120"
                    value={item}
                    name={item}
                    autoSize={{ minRows: 2 }}
                    onChange={(e) => handleinternshipTextChange(i, e)}
                  />
                  <Icon
                    type="minus-circle"
                    theme="filled"
                    onClick={() => handleRemovedItems(i, "internship")}
                  />
                  {Removedinternship &&
                    Removedinternship.map(
                      (item) =>
                        item === i && (
                          <p className="ERROR1">Field cant be blank</p>
                        )
                    )}
                </div>
              ))
            : !intialInternInput && (
                <TextArea
                  value={AddInternshiptext}
                  name="AddInternshiptext"
                  autoSize={{ minRows: 2 }}
                  onChange={handletextChange}
                  onBlur={(event) => {
                    console.log("object");
                    setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    validate();
                  }}
                />
              )}
          {AddInternshipinput && (
            <TextArea
              maxLength="120"
              className="TextAreaInput"
              value={AddInternshiptext}
              name="AddInternshiptext"
              autoSize={{ minRows: 2 }}
              onChange={handletextChange}
              onBlur={(event) => {
                console.log("object");
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
            />
          )}
        </div>
        <Icon
          type="plus-circle"
          theme="filled"
          onClick={() => handleAddText("Internship")}
        />
      </div>
      {BlurCheck["AddInternshiptext"] && errors.AddInternshiptext && (
        <p className="ERROR">
          {BlurCheck["AddInternshiptext"] && errors.AddInternshiptext}
        </p>
      )}
      <div className="Education-Accomplishments-Details">
        <h5 className="NoTextarea">Residency</h5>
        <div className="Location-Details">
          <AutoComplete
            comp="Education"
            setEducation={setEducation}
            Educationdata={Educationdata}
            placeholder="where's your Residency"
            setEditResponse={setEditResponse}
            EditResponse={EditResponse}
          />
        </div>
      </div>

      <div className="Education-Accomplishments-Details">
        <h5>Fellowship</h5>
        <div className="Inputs1">
          {fellowship?.length > 0
            ? fellowship?.map((item, i) => (
                <div className="Inner-Field-Details">
                  <TextArea
                    maxLength="120"
                    value={item}
                    name={item}
                    autoSize={{ minRows: 2 }}
                    onChange={(e) => handlefellowshipTextChange(i, e)}
                  />
                  <Icon
                    type="minus-circle"
                    theme="filled"
                    onClick={() => handleRemovedItems(i, "fellowship")}
                  />
                  {RemovedFellowship &&
                    RemovedFellowship.map(
                      (item) =>
                        item === i && (
                          <p className="ERROR1">Field cant be blank</p>
                        )
                    )}
                </div>
              ))
            : !intialFellowsInput && (
                <TextArea
                  value={AddFellowshiptext}
                  name="AddFellowshiptext"
                  placeholder="Enter your Fellowship"
                  autoSize={{ minRows: 2 }}
                  onChange={handletextChange}
                  onBlur={(event) => {
                    console.log("object");
                    setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    validate();
                  }}
                />
              )}
          {AddFellowshipinput && (
            <TextArea
              maxLength="120"
              className="TextAreaInput"
              value={AddFellowshiptext}
              placeholder="Enter your Fellowship"
              name="AddFellowshiptext"
              autoSize={{ minRows: 2 }}
              onChange={handletextChange}
              onBlur={(event) => {
                console.log("object");
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
            />
          )}
        </div>
        <Icon
          type="plus-circle"
          theme="filled"
          onClick={() => handleAddText("Fellowship")}
        />
      </div>
      {BlurCheck["AddFellowshiptext"] && errors.AddFellowshiptext && (
        <p className="ERROR">
          {BlurCheck["AddFellowshiptext"] && errors.AddFellowshiptext}
        </p>
      )}
    </>
  );
};

export default EditEducation;
