import axios from "axios";
import { baseUrl } from "./config1";
import { showSessionExpiredModal } from "./utills/SessionExpiredModal";
import { hideLoader, showLoader } from "./utills/loaderUtils";

const Axiosinstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

Axiosinstance.interceptors.request.use(
  (config) => {
    showLoader();
    console.log("config", config.headers);
    config.headers["x-auth"] = localStorage.getItem("auth");
    config.headers["x-token"] = localStorage.getItem("x-token");
    config.headers["access-control-allow-origin"] = "*";
    return config;
  },
  (error) => {
    hideLoader();
    // Promise.reject(error)
  }
);

Axiosinstance.interceptors.response.use(
  (response) => {
    hideLoader();
    return response;
  },
  (error) => {
    hideLoader();
    if (error?.response?.status == 401) {
      showSessionExpiredModal();
    }
    if (error.message === "Network Error" && !error.response) {
      console.log("network error");
    }
    const { status, data, config } = error?.response ?? {};
    console.log("Network>>>kkkkstatus", status);

    if (status === 401) { 
    }
    if (
      status === 400 &&
      config.method === "get" &&
      data.errors.hasOwnProperty("id")
    ) {
    }
    if (status === 500) {
      console.log("Network>>>kkkkstatus", status);
    }
    return error.response;
  }
);

export default Axiosinstance;
