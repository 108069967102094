import React from "react";
import { Input } from "antd";

const CustomInput = ({
  className,
  label,
  placeholder,
  suffix,
  prefix,
  name,
  errorText,
  value,
  onChange,
  type,
  onBlur,
  style,
  disabled,
  addonBefore
}) => {
  return (
    <div className={className} label={label} colon={false}>
      <Input
        placeholder={placeholder}
        autoComplete={"off"}
        value={value}
        onChange={onChange}
        name={name}
        suffix={suffix}
        type={type ? type : ""}
        onBlur={onBlur}
        prefix={prefix}
        disabled={disabled}
        addonBefore={addonBefore}
      />
      {errorText && (
        <div
          className="ERROR2"
          style={{
            color: "#f44336",
            fontSize: "1.1rem",
            textAlign: "left",
            marginBottom: "0.6em",
            marginTop: "-0.6em",
          }}
        >
          {errorText}
        </div>
      )}
      {!errorText && <p style={{ marginTop: "0", marginBottom: "1em" }} />}
    </div>
  );
};

export default CustomInput;
