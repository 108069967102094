
import { Fetch_PatientList_Request,PatientList_bookings_Request } from "./PatientListActionTypes";


export const PatientListReducer = (state = {}, action) => {
  switch (action.type) {
    case Fetch_PatientList_Request:
      return {...state,...action.payload};
    default:
      return state;
  }
};

export const PatientList_bookings_Reducer = (state = {}, action) => {
  switch (action.type) {
    case PatientList_bookings_Request:
      return {...state,...action.payload};
    default:
      return state;
  }
};