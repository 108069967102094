import React, { useState } from "react";
import { Upload, message, Icon } from "antd";
import S3 from "react-aws-s3";
import GetlocalStorage from "./localStorageHelper/getLocalStorage";

const CustomUpload = (props) => {
  let getlocalStorage = GetlocalStorage();
  const [filedata, setfiledata] = useState();
  const {
    setUploadstate,
    setspin,
    accept,
    uploadkey,
    email,
    uploadfrom,
    listType,
    className,
    name,
    Dynamic,
    setcurrentfile,
    setAddCertifile,
    setEditResponse,
    uploaddata,
    EditResponse,
    settingsDynamic,
    certification,
  } = props ?? {};
  /** Mandate feilds
   * filePath = path of the uploded file
   * UploadType = its a key to segrigate the type of upload (if let say if user upload image in Lisiting then mention 'Listing')
   * fileData = data of the user selected file
   * UserId = to which we need to add this image to
   */

  const hostid = getlocalStorage?.Hostid;
  const handleupload = (info, key) => {
    if (Dynamic || settingsDynamic) {
      if (key === "AddCertifile") {
        setspin((prevState) => ({
          ...prevState,
          currentfile: true,
        }));
      } else {
        setspin((prevState) => ({
          ...prevState,
          updatefile: true,
        }));
      }
      setcurrentfile(key);
    } else {
      setspin((prevState) => ({
        ...prevState,
        [key]: true,
      }));
    }

    if (filedata && filedata.size <= 5000000) {
      const newFileName = filedata?.name.replace(/\..+$/, "");
      const config = {
        bucketName: "ub-dev-image-uploader",
        dirName: hostid || email /* optional */,
        region: "ap-south-1",
        accessKeyId: "AKIAUCJ3HQRT2EX5ETET",
        secretAccessKey: "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",
      };
      //   let timeValue = Date.now || function () {
      //     return +new Date;
      // };
      let mediaFileName = uploadfrom;
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(filedata, newFileName, mediaFileName).then(
        (data) => {
          console.log("data", data);
          if (data.status === 204) {
            if (props.support) {
              setUploadstate((prevState) => ({
                ...prevState,
                imgUrls: [...prevState.imgUrls, data.location],
              }));
            } else if (Dynamic) {
              if (key === "AddCertifile") {
                setAddCertifile(data.location);
                setspin((prevState) => ({
                  ...prevState,
                  currentfile: false,
                }));
                setcurrentfile();
              } else {
                console.log("setcurrentfile");
                let newArr = { ...uploaddata };
                newArr.Certifications[key] = data.location;
                setUploadstate(newArr);
                setspin((prevState) => ({
                  ...prevState,
                  updatefile: false,
                }));
                setcurrentfile();
              }
            } else if (listType) {
              if (listType === "picture-avatar") {
                setUploadstate(data.location);
                setspin((prevState) => ({
                  ...prevState,
                  [key]: false,
                }));
              } else {
                setUploadstate((prevState) => ({
                  ...prevState,
                  [key]: data.location,
                }));
                setspin((prevState) => ({
                  ...prevState,
                  [key]: false,
                }));
                setEditResponse((prevState) => ({
                  ...prevState,
                  [key]: data.location,
                }));
              }
            } else if (settingsDynamic) {
              console.log("AddCertifile", settingsDynamic);
              if (key === "AddCertifile") {
                setAddCertifile(data.location);
                setspin((prevState) => ({
                  ...prevState,
                  currentfile: false,
                }));
                setcurrentfile();
              } else {
                let newArr = { ...uploaddata };
                newArr.certification[key] = data.location;
                setUploadstate(newArr);
                const editarray = { ...EditResponse, certification };
                editarray.certification[key] = data.location;
                setEditResponse(editarray);
                setspin((prevState) => ({
                  ...prevState,
                  updatefile: false,
                }));
                setcurrentfile();
              }
            } else {
              setUploadstate((prevState) => ({
                ...prevState,
                [key]: data.location,
              }));
              if (setEditResponse) {
                setEditResponse((prevState) => ({
                  ...prevState,
                  [key]: data.location,
                }));
              }
              setspin((prevState) => ({
                ...prevState,
                [key]: false,
              }));
            }
          }
        }
      );
    } else {
      setspin((prevState) => ({
        ...prevState,
        [key]: false,
      }));
      return message.error(
        `${filedata.name} is too large Maximum file size is 5MB`
      );
    }
  };
  const fileUploadRequest = ({ file }) => {
    setfiledata(file);
  };

  return (
    <Upload
      customRequest={fileUploadRequest}
      name={name}
      listType={listType}
      className={className}
      accept={accept}
      onChange={(e) => handleupload(e, uploadkey)}
    >
      {(listType || settingsDynamic) && props.children}

      {!listType && !settingsDynamic && (
        <Icon className="UPLOAD" type="cloud-upload" />
      )}
    </Upload>
  );
};

export default CustomUpload;
