import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../DashboardLayout';
import "./Reviews.scss";
import moment from 'moment';
import { Modal, Rate, Table, Icon, message } from 'antd';
import ViewReview from './components/ViewReview';
import Axiosinstance from "../../../../config";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Reviews = () => {
    const [reviewsData, setReviewsData] = useState([])
    const [viewReviews, setViewReviews] = useState({})
    const [viewReviewModal, setViewReviewModal] = useState(false)
    const history = useHistory();
    const Doctor_id = localStorage.getItem('Doctorid')

    const GetAllReviews = async () => {
        try {
            const response = await Axiosinstance.get(`client/doc/v1.0/getReviews/vewAll/${Doctor_id}`)
            if (response.status >= 200 && response.status <= 300) {
                setReviewsData(response?.data?.data);
                console.log("namanam", response?.data?.data)
            } else return []
        } catch (error) {
            console.error("Error fetching reviews:", error);
            message.info("Something went wrong");
            return [];
        }
    }

    useEffect(() => {
        GetAllReviews()
    }, [])

    const columns = [
        {
            title: "Date",
            dataIndex: "created_at",
            key: "created_at",
            render: (value) => {return moment(value).format("MM-DD-YYYY")},
        },
        {
            title: "Rating",
            dataIndex: "rating",
            key: "rating",
            render: (rating) => {
                return (
                    <span>
                        <Rate value={rating} />
                    </span>
                );
            },
        },
        {
            title: "Reviewer Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Reviewer Mail",
            dataIndex: "email",
            key: "email",
        },
        // {
        //     title: "Status",
        //     dataIndex: "status",
        //     key: "status",
        // },
        {
            title: "Reviews",
            dataIndex: "reviews",
            key: "reviews",
            render: (text, data) => {
                return (
                    <span>
                        {text}
                    </span>
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "view",
            key: "view",
        },
    ];

    const handleReviewModal = (item) => {
        setViewReviews(item)
        setViewReviewModal(true)
    }

    let ReviewsTable = [];

    for (let detail of reviewsData) {
        ReviewsTable.push({
            created_at: detail?.created_at,
            rating: detail?.rating,
            name: detail?.patientName,
            email: detail?.patientEmail,
            reviews: (<span>{detail?.review?.slice(0,15)}</span>),
            status: (<span>{detail?.isApproved === false && detail?.isRejected === false ? "Pending" : "Approved"}</span>),
            view: (<Icon type="eye" onClick={() => handleReviewModal(detail)} />)
        })
    }

    return (
        <DashboardLayout>
            <div className='Reviews_container'>
                <h3>
                    <Icon type='arrow-left'
                    onClick={() => history.push("/dashboardsettings")}
                    />
                    Reviews
                    </h3>
                <div>
                    <Table columns={columns} dataSource={ReviewsTable} />
                </div>
                <Modal
                    visible={viewReviewModal}
                    centered
                    onCancel={() => setViewReviewModal(false)}
                    footer={false}
                    className="view_review_modal">
                    <ViewReview viewReviews={viewReviews} />
                </Modal>
            </div>
        </DashboardLayout>
    )
}

export default Reviews;
