import React, { useState, useEffect } from "react";
import { Layout, Menu, Divider, Icon, message } from "antd";
import "./DashboardLayout.scss";
import header_logo from "../../Assets/header_logo.png";
import header_icon from "../../Assets/Logoicon.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Doctor_Logout_Action } from "../../Redux/Login/LoginAction";
import clearLocalStorage from "../../Common/localStorageHelper/clearLocalStorage";

const { Sider, Content } = Layout;

const DashboardLayout = (props) => {
  const [collapsed, setCollapsedValue] = useState(false);
  const [MenuKey, setMenuKey] = useState();
  const location = useLocation();

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setMenuKey(location.pathname);
  }, [location.pathname]);

  const toggle = () => {
    setCollapsedValue((prestate) => !prestate);
  };

  const Routercall = (val) => {
    history.push("/");
    message.success(val);
  }; 

  const Redirectlogin = () => {
    clearLocalStorage();
    history.push("/");
  };

  return (
    <div className="Dashboard-container">
      <Layout style={{ backgroundColor: "#f3f8ff" }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          id="side_bar"
          className="Dashboard-Sider"
          style={{
            width: "unset",
            minWidth: "unset",
            maxWidth: "unset",
            flex: "unset",
          }}
        >
          <div className="Dashboard-Sider-container">
            <div className="sidebar-logo-image">
              {!collapsed ? (
                <img
                  className="Header_Logo"
                  src={header_logo}
                  onClick={toggle}
                  alt="/"
                />
              ) : (
                <img
                  className="header_icon"
                  src={header_icon}
                  onClick={toggle}
                  alt="/"
                />
              )}
            </div>
            <div className="sidebar-menu-conatiner">
              <div className="sidebar-menu-items-container">
                <Menu className="sidebar-menu" theme="dark" mode="inline">
                  <Menu.Item key="1">
                    <div className="DashboardSider_Menu">
                      <img
                        src={
                          MenuKey === "/maindashboard"
                            ? require("../../Assets/home (2)-1.svg")
                            : require("../../Assets/home (2).svg")
                        }
                        alt="/"
                      />
                      {!collapsed ? (
                        <>
                          <span
                            className={
                              MenuKey === "/maindashboard"
                                ? "Sider-Name1"
                                : "Sider-Name"
                            }
                          >
                            Dashboard
                          </span>
                          {MenuKey === "/maindashboard" && (
                            <Divider type="vertical" />
                          )}
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <Link to="/maindashboard" />
                  </Menu.Item>
                  <Menu.Item key="2">
                    <div className="DashboardSider_Menu">
                      <img
                        src={
                          MenuKey === "/DashboardAppointments"
                            ? require("../../Assets/Group 11338-1.svg")
                            : require("../../Assets/Group 11338.svg")
                        }
                        alt="/"
                      />
                      {!collapsed ? (
                        <>
                          <span
                            className={
                              MenuKey === "/DashboardAppointments"
                                ? "Sider-Name1"
                                : "Sider-Name"
                            }
                          >
                            Appointments
                          </span>
                          {MenuKey === "/DashboardAppointments" && (
                            <Divider type="vertical" />
                          )}
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <Link to="/DashboardAppointments" />
                  </Menu.Item>
                  <Menu.Item key="3">
                    <div className="DashboardSider_Menu">
                      <img
                        src={
                          MenuKey === "/dashboardbookings"
                            ? require("../../Assets/booking-1.svg")
                            : require("../../Assets/booking.svg")
                        }
                        alt="/"
                      />
                      {!collapsed ? (
                        <>
                          <span
                            className={
                              MenuKey === "/dashboardbookings"
                                ? "Sider-Name1"
                                : "Sider-Name"
                            }
                          >
                            Schedules
                          </span>
                          {MenuKey === "/dashboardbookings" && (
                            <Divider type="vertical" />
                          )}
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <Link to="/dashboardbookings" />
                  </Menu.Item>
                  <Menu.Item key="4">
                    <div className="DashboardSider_Menu">
                      <img
                        src={
                          MenuKey === "/dashboardpatientlist"
                            ? require("../../Assets/medical-1.svg")
                            : require("../../Assets/medical.svg")
                        }
                        alt="/"
                      />
                      {!collapsed ? (
                        <>
                          <span
                            className={
                              MenuKey === "/dashboardpatientlist"
                                ? "Sider-Name1"
                                : "Sider-Name"
                            }
                          >
                            Patient List
                          </span>
                          {MenuKey === "/dashboardpatientlist" && (
                            <Divider type="vertical" />
                          )}
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <Link to="/dashboardpatientlist" />
                  </Menu.Item>
                  <Menu.Item key="5">
                    <div className="DashboardSider_Menu">
                      <img
                        src={
                          MenuKey === "/dashboardpayments"
                            ? require("../../Assets/dollar-1.svg")
                            : require("../../Assets/dollar.svg")
                        }
                        alt="/"
                      />
                      {!collapsed ? (
                        <>
                          <span
                            className={
                              MenuKey === "/dashboardpayments"
                                ? "Sider-Name1"
                                : "Sider-Name"
                            }
                          >
                            Payments
                          </span>
                          {MenuKey === "/dashboardpayments" && (
                            <Divider type="vertical" />
                          )}
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <Link to="/dashboardpayments" />
                  </Menu.Item>
                  <Menu.Item key="6">
                    <div className="DashboardSider_Menu">
                      <img
                        src={
                          MenuKey === "/dashboardsettings"
                            ? require("../../Assets/setting (3).svg")
                            : require("../../Assets/settings1.svg")
                        }
                        alt="/"
                      />
                      {!collapsed ? (
                        <>
                          <span
                            className={
                              MenuKey === "/dashboardsettings"
                                ? "Sider-Name1"
                                : "Sider-Name"
                            }
                          >
                            Settings
                          </span>
                          {MenuKey === "/dashboardsettings" && (
                            <Divider type="vertical" />
                          )}
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <Link to="/dashboardsettings" />
                  </Menu.Item>
                  {/* <Menu.Item key="7">
                    <div className="DashboardSider_Menu">
                      <img
                        src={
                          MenuKey === "/dashboard-reports"
                          ? require("../../Assets/google-docs copy-1.svg")
                          : require("../../Assets/google-docs copy.svg")
                        }
                        alt="/"
                      />
                      {!collapsed ? (
                        <>
                          <span
                            className={
                              MenuKey === "/dashboard-reports"
                                ? "Sider-Name1"
                                : "Sider-Name"
                            }
                          >
                            Reports
                          </span>
                          {MenuKey === "/dashboard-reports" && (
                            <Divider type="vertical" />
                          )}
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <Link to="/dashboard-reports" />
                  </Menu.Item> */}
                  {/* <Menu.Item key="8">
                    <div className="DashboardSider_Menu">
                      <img
                        src={
                          MenuKey === "/help-support"                                              
                        ? require("../../Assets/help-1.svg")
                        : require("../../Assets/help1.svg")
                        }
                        alt="/"
                      />
                      {!collapsed ? (
                        <>
                          <span
                            className={
                              MenuKey === "/help-support"
                                ? "Sider-Name1"
                                : "Sider-Name"
                            }
                          >
                            Help & Support
                          </span>
                          {MenuKey === "/help-support" && (
                            <Divider type="vertical" />
                          )}
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <Link to="/help-support" />
                  </Menu.Item> */}
                </Menu>
              </div>
            </div>
            <div className="logout-conatiner">
              {!collapsed ? (
                <h4 className="Logout" onClick={Redirectlogin}>
                  <Icon type="logout" /> &nbsp; Logout
                </h4>
              ) : (
                <h4 className="Logout" onClick={Redirectlogin}>
                  <Icon type="logout" />
                </h4>
              )}
            </div>
          </div>
        </Sider>

        <Layout>
          <Content
            className={
              !collapsed ? "Dashboard-Ant-Content" : "Dashboard-Ant-Content1"
            }
            style={{ padding: 0 }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default DashboardLayout;
