import {
  Create_Appointment_Request,
  Get_Appointment_Request,
  Cancel_Appointments_Request,
  Update_Appointments_Request,
  Get_Schedule_Request
} from './CreateAppointmentActionTypes';

export const Create_Appointment_Reducer = (state = {}, action) => {
  switch (action.type) {
    case Create_Appointment_Request:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const Get_Appointment_Reducer = (state = {}, action) => {
  switch (action.type) {
    case Get_Appointment_Request:
      return [...action.payload];
    default:
      return state;
  }
};

export const Cancel_Appointments_Reducer = (state = {}, action) => {
  switch (action.type) {
    case Cancel_Appointments_Request:
      return [...action.payload];
    default:
      return state;
  }
};

export const Update_Appointments_Reducer = (state = {}, action) => {
  switch (action.type) {
    case Update_Appointments_Request:
      return {...action.payload};
    default:
      return state;
  }
};

export const Get_Schedule_Reducer = (state = {}, action) => {
  switch (action.type) {
    case Get_Schedule_Request:
      return {...action.payload};
    default:
      return state;
  }
};
