import Axiosinstance from "../../config";
import { Fetch_Payments_Request } from "./PaymentActionTypes";

const Paymentsapi="/payments/v1.0/getPayments"


const PaymentList_fetch = (id) => Axiosinstance.get(`${Paymentsapi}/${id}`);

export const PaymentsList_fetch_Action = (id,routercall,handleLoader) => async (dispatch) => {
  let res;
  handleLoader(true)
  try {
    res = await PaymentList_fetch(id);
      
    if (res.status === 200) {
      dispatch(PaymentList_fetch_Request(res.data));
      handleLoader(false)
    } else if(res.status === 401) {
      routercall("/")
      handleLoader(false)
    }
    
    
  } catch (error) {
    console.log("PaymentList__fetch_Request-error", error);
  }
}

const PaymentList_fetch_Request = (PaymentListinfo) => ({
    type: Fetch_Payments_Request,
    payload: PaymentListinfo,
  });