import React, { useState, useCallback, useEffect } from "react";
import { Icon, Spin } from "antd";
import { useParams } from "react-router-dom";
import Video from "twilio-video";
import Lobby from "./Lobby";
import Room from "./Room";

import './VideoChat.scss';
import { baseUrl } from "../../config1";

const VideoChat = () => {
  const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState("");
  const [room, setRoom] = useState(null);
  const [token, setToken] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const [voiceStatus, setVoiceStatus] = useState('Mute');
  const [videoStatus, setVideoStatus] = useState('Stop Video');
  const [isAppointmentdone, setisAppointmentdone] = useState(false);
  const [Loading, setLoading] = useState(true);

  var screenTrack, shareScreen = {
    innerHTML: "Share screen",
    disabled: false
  };
  let { un, rn } = useParams();
  console.log('un, rn', un, rn)
  const handleUsernameChange = useCallback((event) => {
    setUsername(event.target.value);
  }, []);

  const handleRoomNameChange = useCallback((event) => {
    setRoomName(event.target.value);
  }, []);

  useEffect(async() => {
    console.log('reg')
    setConnecting(true);
    const data = await fetch(baseUrl +
      //"http://localhost:8000/api
      "twilio/v1.0/createToken/video/token", {
      method: "POST",
      body: JSON.stringify({
        identity: un,
        room: rn,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
    setToken(data.token)
    Video.connect(data.token, {
      name: roomName,
    })
      .then((room) => {
        shareScreen.disabled = false;
        setConnecting(false);
        setRoom(room);
      })
      .catch((err) => {
        console.error(err);
        setConnecting(false);
      });
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setConnecting(true);
      const data = await fetch(baseUrl +
        //"http://localhost:8000/api
        "twilio/v1.0/createToken/video/token", {
        method: "POST",
        body: JSON.stringify({
          identity: username,
          room: roomName,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());
      setToken(data.token)
      Video.connect(data.token, {
        name: roomName,
      })
        .then((room) => {
          shareScreen.disabled = false;
          setLoading(false);
          setConnecting(false);
          setRoom(room);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setConnecting(false);
        });
    },
    [roomName, username]
  );

  const handleLogout = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
        setisAppointmentdone(true);
        shareScreen.innerHTML = 'Share screen';
        shareScreen.disabled = true;
      }
      return null;
    });
  }, []);



  useEffect(() => {
    if (room) {
      const tidyUp = (event) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, handleLogout]);

  const handleAudio = () => {
    if (voiceStatus === "Mute") {
      room.localParticipant.audioTracks.forEach(audioTrack => {
        audioTrack.track.disable();
      });
      setVoiceStatus("Unmute");
    } else {
      room.localParticipant.audioTracks.forEach(audioTrack => {
        audioTrack.track.enable();
      });
      setVoiceStatus("Mute");
    }
  }

  const handleVideo = () => {
    console.log('videoStatus..', videoStatus)
    if (videoStatus === "Stop Video") {
      room.localParticipant.videoTracks.forEach(videoTrack => {
        videoTrack.track.disable();
      });
      setVideoStatus("Start Video");
    } else {
      room.localParticipant.videoTracks.forEach(videoTrack => {
        videoTrack.track.enable();
      });
      setVideoStatus("Stop Video");
    }
  }

  const handleScreen = useCallback(async () => {
    if (!screenTrack) {
      navigator.mediaDevices.getDisplayMedia().then(async (stream) => {

        screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0]);
        console.log('screenTrack', screenTrack)
        //  await Video.connect(token, {
        //   name: 'presentation',
        //   tracks: [screenTrack]
        // });
        Video.connect(token, {
          name: roomName,
          tracks: [screenTrack]
        })
        // .then((room) => {
        //   shareScreen.disabled = false;
        //   setConnecting(false);
        //   setRoom(room);
        // })
        // .catch((err) => {
        //   console.error(err);
        //   setConnecting(false);
        // });
        // document.getElementById('screen').srcObj = stream;
        room.localParticipant.publishTrack(screenTrack);
        //console.log('screenTrack',room.localParticipant.tracks.push(screenTrack))
        shareScreen.innerHTML = 'Stop sharing';

        // screenTrack.mediaStreamTrack.onended = () => { handleScreen() };

      }).catch(() => {
        alert('Could not share the screen.');
      });
    }
    else {
      // console.log('screenTrack...',screenTrack)
      room.localParticipant.unpublishTrack(screenTrack);
      screenTrack.stop();
      screenTrack = null;
      shareScreen.innerHTML = 'Share screen';
    }
  }, [room]);
  const antIcon = <Icon type="loading" spin />;
  let render;
   if (room) {
    render = (
      <Room roomName={rn} room={room} handleLogout={handleLogout} handleAudio={handleAudio}
        handleVideo={handleVideo} handleScreen={handleScreen} voiceStatus={voiceStatus} videoStatus={videoStatus} shareScreen={shareScreen}
      />
    );
   } 
 else {
  render = (
    <div className="videocall_spin">
      {!isAppointmentdone ? (
        <Spin
          tip="Video Call Loading..."
          style={{ textAlign: "center" }}
          spinning={Loading}
          // indicator={antIcon}
        />
      ) : (
        <h2 style={{ textAlign: "center" }}> Video Call Ended...</h2>
      )}
    </div>
  );
  }
  return render;
};

export default VideoChat;