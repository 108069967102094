
import { Profile_Get_Request,Profile_Update_Request } from "./ProfileActionTypes"


export const ProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case Profile_Get_Request:
      return {...state,...action.payload};
      case Profile_Update_Request:
        return {...state,...action.payload};
    default:
      return state;
  }
};
