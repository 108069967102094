import { v4 as uuidv4 } from 'uuid';

const { Producer } = require('sqs-producer');

var sendEmailToQueue = async (toEmail, text, subject) => {
    try {
        const producer = Producer.create({
            queueUrl: "https://sqs.ap-south-1.amazonaws.com/279833969767/UB_send_NotificationEmail.fifo",
            region: 'ap-south-1',
            accessKeyId: 'AKIAUCJ3HQRTV4MJQCZH',
            secretAccessKey: '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'
        });

        await producer.send({
            id: `${uuidv4()}`,
            body: "Ubora Health",
            messageAttributes: {
                toEmail: { DataType: 'String', StringValue: toEmail },
                text: { DataType: 'String', StringValue: text },
                subject: { DataType: 'String', StringValue: subject }
            },
            groupId: `${uuidv4()}`,// user ID
            deduplicationId: `${uuidv4()}` // typically a hash of the message body
        });
        // get the current size of the queue
        const size = await producer.queueSize();
        console.log(`There are ${size} messages on the queue.`);
    } catch (error) {
        console.log("error", error);
    }
}

export default sendEmailToQueue;
