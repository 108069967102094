import React from "react";
import { Link } from "react-router-dom";
import "./Privacy.scss";
import { Divider, Switch, Card, Col, Row } from "antd";
import DashboardLayout from "./../../DashboardLayout";

const Privacy = (props) => {

  const changeStatus = (checked, e) => {};

  return (
    <DashboardLayout>
      <div className="DashboardSettings-container">
        <div className="DashboardSettings-body">
          <Link to="/dashboardsettings">
            <h3 className="DashboardSettings-Title">
              {"Settings < Privacy & Sharing"}
            </h3>
          </Link>
          <div className="Privacy-Container">
            <div className="Privacy-Body-Content">
              <div className="Privacy-Body-Row">
                <Row>
                  <Col lg={16} xs={24}>
                    <div className="Privacy-Body-Edit">
                      <h3>Your Privacy</h3>
                      <h3 className="Privacy-Edit">Edit</h3>
                      <h3 className="Privacy-Content">
                        We use cookies to help personalise content, tailor and
                        measure ads, and Provide a safer experience
                      </h3>
                      <Divider className="Privacy-divider" />
                    </div>
                    <div className="Privacy-Body-Edit">
                      <h3>Share my activity on Facebook</h3>
                      <p className="Privacy-Switch">
                        <Switch name="facebook" onChange={changeStatus} />
                      </p>
                      <h3 className="Privacy-Content">
                        We use cookies to help personalise content, tailor and
                        measure ads, and Provide a safer experience
                      </h3>
                      <Divider className="Privacy-divider" />
                    </div>
                    <div className="Privacy-Body-Edit">
                      <h3>Include my profile and listings in search engines</h3>
                      <p className="Privacy-Switch-two">
                        <Switch name="searchEngines" onChange={changeStatus} />
                      </p>
                      <h3 className="Privacy-Content-two">
                        We use cookies to help personalise content, tailor and
                        measure ads, and Provide a safer experience
                      </h3>
                      <Divider className="Privacy-divider" />
                    </div>
                  </Col>
                  <Col lg={8} sm={16} xs={24}>
                    <div className="Privacy-Share">
                      <Card>
                        <img
                          src={require("../../../../Assets/international-icon.svg")}
                          alt="logo"
                        />
                        <p>Pick what you share</p>
                        <p className="Privacy-Share-Content">
                          Ubora cares about your privacy. Use this Screen to
                          choose how you want to share Your activity.
                        </p>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Privacy;
