import React, { useState } from "react";
import pin from "../../Assets/pin.svg";
// import "./AutoComplete.scss";
// import CustomInput from "./../custom-input";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

const AutoComplete = (props) => {
  const [address, setAddress] = useState(null);

  const handleItemClicked = async (place) => {
    const results = await geocodeByAddress(place.description);
    const latLng = await getLatLng(results[0]);
    const city = place?.terms.slice(-3)[0]?.value;
    const statename = place?.terms.slice(-2)[0]?.value;
    const countryName = place?.terms.slice(-1)[0]?.value;
    const placeZipcode = results[0].address_components.slice(-1)[0].short_name;
    const { lat, lng } = latLng;

    console.log("results[0]", latLng);
    console.log(
      "placeddddds>:::::::",
      results[0].address_components.slice(-1)[0].short_name
    );


    if (props.comp === "Education") {
      props.setEducation((prevState) => ({
        ...prevState,
        residency: place.description,
      }));

      if (props.setEditResponse) {
        props.setEditResponse((prevState) => ({
          ...prevState,
          residency: place.description,
        }));
      }
    } else {
      props.setSignupform((prevState) => ({
        ...prevState,
        zip: placeZipcode && placeZipcode.length > 4 ? placeZipcode : "",
        address: {
          ...prevState.address,
          street:place.description,
          city: city,
          state: statename,
          country: countryName,
          zipCode:  placeZipcode && placeZipcode.length > 4 ? placeZipcode : "",
        },
      location: {
          coordinates: [lat, lng],
        },
      }));

      props.setlocationerror();
    }
  };

  const { residency } = props.Educationdata ?? [];
  return (
    <div
      className={
        props.className ? props.className : "AutocompletePlace-container"
      }
    >
      <GooglePlacesAutocomplete
        placeholder={props.placeholder ? props.placeholder : "Address"}
        // apiKey="AIzaSyBi8pX7M5i-_-7OcZt4S11db4epWDhzjOc" // Local Host
        apiKey="AIzaSyBeTqzGis1Qo3NRZTYGl6kD28MHq9TlGQ8" // Production
        value={address}
        onChange={setAddress}
        onSelect={handleItemClicked}
        initialValue={props.comp === "Education" ? residency : null}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["us", "in"],
          },
        }}
      />
      <img className="PinIMG" src={pin} alt="pinImage" />
    </div>
  );
};

export default AutoComplete;
