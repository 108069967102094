import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
 
const ConsultationOverview = () => {
  const AppiontmentsCount = useSelector(
    (state) => state.AppointmentsCountReducer.data,
  );

  const [Consultations, setConsultations] = useState({
    series: [
      {
        name: "series1",
        data: [34, 40, 28, 52, 42, 109, 100],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#6D5EFF"],
      xaxis: {
        lines: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        x: {
          format: "MM/DD/YYYY HH:mm",
        },
      },
    },
  });

  return (
    <div className="Overview-Details-1">
      <div className="Overview-Details-Title">
        <h4>Completed</h4>
        {/* <p className="OverviewDay">Today</p> */}
      </div>
      <div className="Overview-Details-Count">
      <img src={require("../../../../Assets/Group 13347.svg")} alt=""/>
        <h1>
          {AppiontmentsCount &&
            (AppiontmentsCount.completed === undefined
              ? 0
              : AppiontmentsCount.completed)}
        </h1>
        {/* <p style={{ color: "#6D5EFF" }} className="OverviewDay-Count">
          +35
        </p> */}
      </div>
      {/* <ReactApexChart
        options={Consultations.options}
        series={Consultations.series}
        type="area"
        height="80"
      /> */}
   
    </div>
  );
};

export default ConsultationOverview;
