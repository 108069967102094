import { setIsMdlogged, setProfilePhoto } from "../../Common/localStorageHelper/setLocalStorage";
import Axiosinstance from "../../config";
import { Profile_Get_Request } from "./ProfileActionTypes";
import { message } from "antd";

const Profile_api = "profile/v1.0/getUser";

const Profile_fetch = (id) => Axiosinstance.get(`${Profile_api}/${id}`);

export const Profile_fetch_Action =
  (id, routercall, handleLoader) => async (dispatch) => {
    let res;
    handleLoader(true);
    try {
      res = await Profile_fetch(id);
      if (res.status === 200) {
        dispatch(Profile_fetch_Request(res.data.data));
        handleLoader(false);
      } else if (res.status === 401) {
        routercall();
        handleLoader(false);
      }
    } catch (error) {
      console.log(" Profile_fetch_Action-error", error);
    }
  };

const Profile_update_api = "auth/v1.0/updateuserprofile";

const Profile_update_User = (id, data) =>
  Axiosinstance.put(`${Profile_update_api}/${id}`, data);

// const Profile_update_User = (id,data) =>Axiosinstance.get(`${Profile_update_Userapi}/${id}`,data);
export const Profile_Update_Action =
  (id, data, handleLoader, routercall) => async (dispatch) => {
    let res;
    handleLoader(true);
    try {
      res = await Profile_update_User(id, data);
      if (res.status === 200) {
        handleLoader(false);
      } else if (res.status === 401) {
        routercall();
        handleLoader(false);
      } else return [];
    } catch (error) {
      console.log(" Profile_fetch_Action-error", error);
    }
  };

// const Profile_Create_User = (id,data) => Axiosinstance({
//   method: "post",
//   url:`${Profile_CreateUserapi}/${id}`,
//   headers: {
//     "Content-Type": "application/json",
//     "x-token":token,
//    'x-auth':authtoken,
//   },
//   data: data,
// });

const Profile_CreateUserapi = "profile/v1.0/profile";
const Profile_Create_User = (id, data) =>
  Axiosinstance.post(`${Profile_CreateUserapi}/${id}`, data);
export const Profile_CreatePro_Action =
  (data, id, Routercall) => async (dispatch) => {
    let res;
    try {
      res = await Profile_Create_User(data, id);

      if (res?.status === 200) {
        setIsMdlogged(true) 
        message.success(res?.data?.message);
        if (res?.data?.data?.profilePhoto) { 
          setProfilePhoto(res?.data?.data?.profilePhoto)
        }
        Routercall();
      } else {
        message.error(res?.data?.message);
      }
    } catch ({ e }) {
      message.error(res?.data?.message);
      console.log(" Profile_Profile_Create_User-error", e);
    }
  };

const Profile_fetch_Request = (Profileinfo) => ({
  type: Profile_Get_Request,
  payload: Profileinfo,
});
