import React from "react";
import { List } from "antd";

const HealthProfile = ({ Healthprofiledata , PatientPreview}) => {
  const {
    // patientName,
    // gender,
    email,
    height,
    weight,
    bloodGroup,
    medicalIssues,
    allergies,
    habits,
  } = Healthprofiledata ?? {};

  const {age,bookingId,createdAt,gender,issue,patientAddress, patientEmail, patientId,patientName, patientPhone}= PatientPreview ?? {};

  const formatDisplayPhoneNumber=(phoneNumberString)=> {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = '+1 ';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
  const Patient_ContactInfo = [
    { label: "Patient Name", content: patientName },
    { label: "Gender", content: gender },
    { label: "Email", content: patientEmail,className: "email_text"  },
    { label: "phone Number", content: `${formatDisplayPhoneNumber(patientPhone)}` },
    { label: "Address", content: patientAddress },
    { label: "Age", content: age },
    { label: "Booking ID", content: bookingId },
    { label: "Patient ID", content: patientId },
    { label: "Issues", content: issue },
    // { label: "Height", content:height&& height[0].value + height[0].measurement },
    // { label: "Weight", content: weight&&weight[0].value + weight[0].measurement },
    // { label: "Blood Group", content: bloodGroup },
    // { label: "Medical Condition", content: medicalIssues },
    // { label: "Allergies", content: allergies },
  ];

  const Patienthabits = [];
  for (const [key, value] of Object.entries(habits ?? {})) {
    Patienthabits.push({
      label: key,
      content: value,
    });
  }

  return (
    <div>
      <List
        className="HealthProfile-Content"
        itemLayout="horizontal"
        dataSource={Patient_ContactInfo}
        grid={{ gutter: [16, 16], md: 1 }}
        renderItem={(item) => (
          <List.Item>
            <h5>{item.label}</h5>
            <span>:</span>
            <h4 className={item.label === "Email" ? "email_text" : ""}>
              {Array.isArray(item.content)
                ? item.content.map((item) => `${item},`)
                : item.content}
            </h4>
          </List.Item>
        )}
      />
      <List
        className="HealthProfile-Content1"
        itemLayout="horizontal"
        dataSource={Patienthabits}
        grid={{ gutter: [16, 16], md: 3, xl: 3 }}
        renderItem={(item) => (
          <>
            <List.Item>
              <h1 style={{textTransform:"capitalize"}}>{item.label}</h1>
              <h1>:</h1>
              <h1>{item.content}</h1>
            </List.Item>
          </>
        )}
      />
    </div>
  );
};

export default HealthProfile;
