import React, { useEffect, useState } from "react";
import Participant from "./Participant";
import { Button, Icon } from "antd";

const Room = ({ roomName, room, handleLogout, handleAudio, handleVideo, handleScreen, videoStatus, voiceStatus, shareScreen }) => {
 console.log('room',room)
  const [participants, setParticipants] = useState([]);
console.log('shareScreen',shareScreen)
  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  const remoteParticipants = participants.map((participant) => (
    <Participant className="Remote-Video" key={participant.sid} participant={participant} />
  ));

  return (
    <div className="room">
      {/* <h2>Room: {roomName}</h2> */}
      
      <div className="local-participant">
        {room ? (
          <Participant className={participants.length === 0 ? "Remote-Video" : "Local-Video"}
            key={room.localParticipant.sid}
            participant={room.localParticipant}

          />
        ) : (
          ""
        )}
      </div>

      <div className="Call-Btns-Content">
        <Button className={voiceStatus === "Unmute" ? "audio2" : "audio"} onClick={handleAudio} icon="audio" shape="circle" />
        <Button className="logout" onClick={handleLogout} shape="circle">
          <Icon rotate={225} type="phone" theme="filled" />
        </Button>
        <Button className={videoStatus === "Start Video" ? "video2" : "video"} onClick={handleVideo}  icon="video-camera" shape="circle" />
        <div onClick={handleScreen} className="Screenshare">
           <Icon type="up-square" />
           <h4>{shareScreen.innerHTML}</h4>
        </div>
      </div>

      {/* <h3>Remote Participants</h3> */}
      <div className="remote-participants">{remoteParticipants}</div>
    </div>
  );
};

export default Room;