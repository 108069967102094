import { Avatar, Button, Upload, Spin, message } from "antd";
import React, { useState } from "react";
import S3 from "react-aws-s3";
import CustomUpload from "../../../../../Common/custom-upload";

const PersonalDetails = ({
  setProfileInfo,
  ProfileInfo,
  setEditResponse,
  EditResponse,
}) => {
  const [spin, setSpin] = useState({ profilePhoto: false });
  const [filedata, setfiledata] = useState();

  const handleupload = (info, key) => {
    setSpin(true);
    if (filedata && filedata.size <= 5000000) {
      const newFileName = filedata?.name.replace(/\..+$/, "");
      const config = {
        bucketName: "ub-provider-image-uploader",
        dirName: "host" /* optional */,
        region: "us-west-1",
        accessKeyId: "AKIAW7CW6QVRSTEZBUOO",
        secretAccessKey: "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",
      };
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(filedata, newFileName).then((data) => {
        if (data.status === 204) {
          setProfileInfo({
            ...ProfileInfo,
            profilePhoto: data.location,
          });
          setEditResponse({
            ...EditResponse,
            profilePhoto: data.location,
          });
          setSpin(false);
        } else {
          console.log("fail");
        }
      });
    } else {
      message.error(`${filedata.name} is too large Maximum file size is 5MB`);
      setSpin(false);
    }
  };

  const fileUploadRequest = ({ file, onSuccess }) => {
    setfiledata(file);
  };

  const { profilePhoto } = ProfileInfo ?? "";
  const { fullName } = ProfileInfo ?? [];
  const { summery, profileHeader } = ProfileInfo ?? "";

  return (
    <div className="Accountinfo-Personaldetails">

      <h2>Personal Information</h2>
      <div className="personal_content_block">
        <div className="profileImg">
          {profilePhoto ? (
            !spin.profilePhoto ? (
              <Avatar size={120} src={profilePhoto} />
            ) : (
              <div className="Accountinfo-Spin">
                <Spin className="AccountInfoSPIN" spinning={spin.profilePhoto} />
              </div>
            )
          ) : (
            <div className="No-Profile">
              <Avatar className="NoProfileImg" size={120}>
                {fullName ? fullName?.replace(/[^a-zA-Z0-9]/g, "").charAt(0) : "U"}
              </Avatar>
            </div>
          )}
          <div>
            <CustomUpload
              accept={"image/*"}
              setUploadstate={setProfileInfo}
              uploadkey={"profilePhoto"}
              uploadfrom={"MdsettingsUpload"}
              setspin={setSpin}
              name="avatar"
              listType={"picture"}
              setEditResponse={setEditResponse}
            >
              <Button className="uploadImg" type="primary">
                Upload Photo
              </Button>
            </CustomUpload>
          </div>
        </div>
        <div className="profileimg_content">
          <h3>{fullName}</h3>
          <h4>{profileHeader}</h4>
          <p>{summery}</p>

        </div>
      </div>
      {/* <Button className="DeleteBtn">Delete</Button> */}
    </div>
  );
};

export default PersonalDetails;
