
import { Doctor_Login_Request, Doctor_Login_Forget,Doctor_Login_reset } from "./LoginActionTypes"


export const Login_Reducer = (state = {}, action) => {
  switch (action.type) {
    case Doctor_Login_Request:
      return {...state,...action.payload};
      case Doctor_Login_Forget:
        return {...state,...action.payload};
        case Doctor_Login_reset:
          return {...state,...action.payload};
    default:
      return state;
  }
};



export const Login_Forget_Reducer = (state = {}, action) => {
  switch (action.type) {
      case Doctor_Login_Forget:
        return {...state,...action.payload};
      
    default:
      return state;
  }
};

export const Login_Reset_Reducer = (state = {}, action) => {
  switch (action.type) {
    case Doctor_Login_reset:
      return {...state,...action.payload};
      
    default:
      return state;
  }
}