import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';
import moment from "moment";
import { composeWithDevTools } from 'redux-devtools-extension';
import 'antd/dist/antd.css';
import rootReducer from './Redux/rootReducer';
const Middleware=[thunk]

const store=createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...Middleware))
  
  )

  moment.updateLocale("en", {
    weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  });

ReactDOM.render(
  <Provider store={store}>
    <App />
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

