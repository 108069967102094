import {  Doctor_Signup_Request } from './SignupActionTypes';

export const  Doctor_SignupReducer = (state = {}, action) => {
  switch (action.type) {
    case Doctor_Signup_Request:
      return {...state,...action.payload};
    default:
      return state;
  }
};
