import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/charts';

const ColumnCharts = () => {
  var data = [
    {
      name: 'Female',
      月份: 'Jan.',
      月均降雨量: 18.9,
    },
    {
      name: 'Female',
      月份: 'Feb.',
      月均降雨量: 28.8,
    },
    {
      name: 'Female',
      月份: 'Mar.',
      月均降雨量: 39.3,
    },
    {
      name: 'Female',
      月份: 'Apr.',
      月均降雨量: 81.4,
    },
    {
      name: 'Female',
      月份: 'May',
      月均降雨量: 47,
    },
    {
      name: 'Female',
      月份: 'Jun.',
      月均降雨量: 20.3,
    },
    {
      name: 'Female',
      月份: 'Jul.',
      月均降雨量: 24,
    },
    {
      name: 'Female',
      月份: 'Aug.',
      月均降雨量: 35.6,
    },
    {
      name: 'Male',
      月份: 'Jan.',
      月均降雨量: 12.4,
    },
    {
      name: 'Male',
      月份: 'Feb.',
      月均降雨量: 23.2,
    },
    {
      name: 'Male',
      月份: 'Mar.',
      月均降雨量: 34.5,
    },
    {
      name: 'Male',
      月份: 'Apr.',
      月均降雨量: 99.7,
    },
    {
      name: 'Male',
      月份: 'May',
      月均降雨量: 52.6,
    },
    {
      name: 'Male',
      月份: 'Jun.',
      月均降雨量: 35.5,
    },
    {
      name: 'Male',
      月份: 'Jul.',
      月均降雨量: 37.4,
    },
    {
      name: 'Male',
      月份: 'Aug.',
      月均降雨量: 42.4,
    },
  ];
  var config = {
    data: data,
    isGroup: true,
    xField: '月份',
    yField: '月均降雨量',
    seriesField: 'name',
    color: ["#4FB8B6", "#2D80B5"],
    maxColumnWidth: 15,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    legend: {
      layout: 'horizontal',
      position: 'bottom'
    },
//     xAxis: {
//       grid: null,
//       label: null
// },
// yAxis: {
//       grid: null,
//       label: null
// },

label: {
  autoHide: true,
  autoRotate: true,
},
  };
  return <Column {...config} />;
};

export default ColumnCharts;