let baseUrl="https://uatdocapi.uborahealth.info/api/";
let UserBaseurl="https://uatdocapi.uborahealth.info/api/";
// console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL);
// const { hostname } = window.location;
// if (hostname === "www.app.doctor.uborahealth.info") {
//   // baseUrl = "https://doctor.uborahealth.info/api";
//   baseUrl = "https://uatdocapi.uborahealth.info/api";
//   UserBaseurl = "https://user.uborahealth.info/api";
// } else if (hostname === "dev.d17rhap571u7kn.amplifyapp") {
//   baseUrl = "https://ubdev.tele.dev2prod.com/api";
//   UserBaseurl = "https://ubdev.user.dev2prod.com/api";
// } else if (hostname === "localhost") {
//   baseUrl = "https://doctor.uborahealth.info/api";
//   UserBaseurl = "https://user.uborahealth.info/api";
// } else {
//   baseUrl = "https://ubdev.tele.dev2prod.com/api";
//   UserBaseurl = "https://ubdev.user.dev2prod.com/api";
// }

  // baseUrl ="http://localhost:8080/api";
  // UserBaseurl = "http://localhost:8080/api";
export { baseUrl, UserBaseurl };
