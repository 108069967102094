const GetlocalStorage = () => {
  return {
    Doctorid: localStorage.getItem("Doctorid"),
    specialtiesId: localStorage.getItem("specialtiesId"),
    token: localStorage.getItem("x-token"),
    isMdlogged: localStorage.getItem("isMdlogged"),
    profilePhoto: localStorage.getItem("profilePhoto"),
    auth: localStorage.getItem("auth"),
    specialtiesName: localStorage.getItem("specialtiesName"),
    fullName: localStorage.getItem("fullName"),
    mdemail: localStorage.getItem("mdemail"),
    profilePhoto: localStorage.getItem("profilePhoto"),
  };
};
export default GetlocalStorage;

//  useage

// let getlocalStorage = GetlocalStorage();
// getlocalStorage.fullNameUser,
