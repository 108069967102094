import React, { useState } from "react";
import "./Notifications.scss";
import { Divider, Switch, Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import DashboardLayout from "./../../DashboardLayout";

const intialNotificationsdata = {
  messages: {
    emails: false,
    textMessages: false,
    browserNotificatis: false,
  },
  remainders: {
    emails: false,
    textMessages: false,
    browserNotificatis: false,
  },
  promotionTips: {
    emails: false,
    textMessages: false,
    browserNotificatis: false,
  },
  polocyCommunity: {
    emails: false,
    textMessages: false,
    browserNotificatis: false,
  },
  accountSupport: {
    emails: false,
    textMessages: false,
    browserNotificatis: false,
  },
};

const Notifications = (props) => {
  const [CheckSwitch, setCheckSwitch] = useState(intialNotificationsdata);
 
  //   useEffect(() => {
  //     dispatch(fetchNotificationPreferences(local_id));
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);
  //   const Notificationsdata = useSelector(
  //     (state) => state.getNotificationPreference?.data,
  //   );

  //   useEffect(() => {
  //     if (Notificationsdata) {
  //       setCheckSwitch(Notificationsdata);
  //     } else {
  //       setCheckSwitch(intialNotificationsdata);
  //     }
  //   }, [Notificationsdata]);

  const CheckedonChange = (checked, event) => {
    const [section, type] = event.target.name.split(".");
    const checkdata = {
      //   userId: local_id,
      ...CheckSwitch,
      [section]: {
        ...CheckSwitch[section],
        [type]: checked,
      },
    };
    setCheckSwitch(checkdata);
    // if (Notificationsdata) {
    //   dispatch(changeNotificationPreferences(checkdata, local_id));
    // } else {
    //   dispatch(CreateNotificationPreferences(checkdata, local_id));
    // }
  };

  return (
    <DashboardLayout>
      <div className="DashboardSettings-container">
        <div className="DashboardSettings-body">
          <Link to="/dashboardsettings">
            <h3 className="DashboardSettings-Title">
              {"Settings < Notifications"}
            </h3>
          </Link>
          <div className="Notifications-Container">
            <div className="Notifications-body-content">
              <div>
                <Row>
                  <Col lg={16}>
                    <div className="Notifications-Content">
                      <h3>Messages</h3>
                      <h3 className="Notifications-Description">
                        Receive messages from hosts and guests, including
                        booking requests.
                      </h3>
                      <p className="Notifications-Content-Title">Email</p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="messages.emails"
                          checked={
                            CheckSwitch ? CheckSwitch.messages.emails : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">
                        Text messages
                      </p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="messages.textMessages"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.messages.textMessages
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">
                        Push notifications
                      </p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="messages.browserNotificatis"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.messages.browserNotificatis
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                    </div>
                    <div className="Notifications-Content">
                      <h3>Remainders</h3>
                      <h3 className="Notifications-Description">
                        Receive booking remainders, requests to write a review,
                        pricing notices and Other reminders related to your
                        activities on Ubora.
                      </h3>
                      <p className="Notifications-Content-Title">Email</p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="remainders.emails"
                          checked={
                            CheckSwitch ? CheckSwitch.remainders.emails : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">
                        Text messages
                      </p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="remainders.textMessages"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.remainders.textMessages
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">
                        Push notifications
                      </p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="remainders.browserNotificatis"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.remainders.browserNotificatis
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                    </div>
                    <div className="Notifications-Content">
                      <h3>Promotions and Tips</h3>
                      <h3 className="Notifications-Description">
                        Receive coupons, promotions, surveys, product updates
                        and inspiration From Ubora and Ubora’s partners.
                      </h3>
                      <p className="Notifications-Content-Title">Email</p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="promotionTips.emails"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.promotionTips.emails
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">
                        Text messages
                      </p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="promotionTips.textMessages"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.promotionTips.textMessages
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">Phone calls</p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="promotionTips.phoneCalls"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.promotionTips.phoneCalls
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">
                        Push notifications
                      </p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="promotionTips.browserNotificatis"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.promotionTips.browserNotificatis
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                    </div>
                    <div className="Notifications-Content">
                      <h3>Policy and Community</h3>
                      <h3 className="Notifications-Description">
                        Receive coupons, promotions, surveys, product updates
                        and inspiration From Ubora and Ubora’s partners.
                      </h3>
                      <p className="Notifications-Content-Title">Email</p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="polocyCommunity.emails"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.polocyCommunity.emails
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">
                        Text messages
                      </p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="polocyCommunity.textMessages"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.polocyCommunity.textMessages
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">Phone calls</p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="polocyCommunity.phoneCalls"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.polocyCommunity.phoneCalls
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">
                        Push notifications
                      </p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="polocyCommunity.browserNotificatis"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.polocyCommunity.browserNotificatis
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                    </div>
                    <div className="Notifications-Content">
                      <h3>Account and Support</h3>
                      <h3 className="Notifications-Description">
                        Receive coupons, promotions, surveys, product updates
                        and inspiration From Ubora and Ubora’s partners.
                      </h3>
                      <p className="Notifications-Content-Title">Email</p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="accountSupport.emails"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.accountSupport.emails
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">
                        Text messages
                      </p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="accountSupport.textMessages"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.accountSupport.textMessages
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">
                        Push notifications
                      </p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="accountSupport.browserNotificatis"
                          checked={
                            CheckSwitch
                              ? CheckSwitch.accountSupport.browserNotificatis
                              : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <Divider className="Notifications-divider" />
                      <p className="Notifications-Content-Title">
                        Unsubscribe from all marketing emails
                      </p>
                      <p className="Notifications-Switch">
                        <Switch
                          name="unsubscibeemails"
                          checked={
                            CheckSwitch ? CheckSwitch.unsubscibeemails : false
                          }
                          onChange={CheckedonChange}
                        />
                      </p>
                      <h3 className="Notifications-Description2">
                        Receive coupons, promotions, surveys, product updates
                        and inspiration From Ubora and Ubora’s partners. Receive
                        coupons, promotions, surveys, product updates and
                        inspiration From Ubora and Ubora’s partners.
                      </h3>
                    </div>
                  </Col>
                  <Col lg={8} sm={16}>
                    <div className="Manage-Notifications">
                      <Card>
                        <img
                          src={require("../../../../Assets/international-icon.svg")}
                          alt="logo"
                        />
                        <p>Manage your Notifications</p>
                        <p className="Manage-Notifications-Content">
                          Always pay and communicate through Ubora to ensure
                          you’re protected Under our
                          <span style={{ color: "#2D80B5" }}>
                            Terms of Service, Payments Terms of Service
                          </span>
                          , Cancellation, and other safeguards.
                          <span style={{ color: "#2D80B5" }}>Learn more</span>
                        </p>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Notifications;
