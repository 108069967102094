import React from 'react'
import { Icon, Table, Badge } from "antd";
const Sessions = ({ data, PatientViewLoader, onclick_DetailsView }) => {
  const columns = [
    { title: "Token Id", dataIndex: "Token_Id", key: "Token_Id" },
    { title: "Booking id", dataIndex: "Booking_id", key: "Booking_id" },
    { title: "Date of session", dataIndex: "Date", key: "Date" },
    { title: "Slot Timings", dataIndex: "Slot_Timings", key: "Slot_Timings" },
    {
      title: "Medication",
      dataIndex: "Medication",
      key: "Medication",
      render: (text, record) => (
        <p
          className="VIEW"
          onClick={() => onclick_DetailsView("Patient-MedicationView", record)}
        >
          view
        </p>
      ),
    },
    {
      title: "Details",
      dataIndex: "Details",
      key: "Details",
      render: (text, record) => (
        <p
          onClick={() => onclick_DetailsView("Patient-DetailsView", record)}
          className="VIEW"
        >
          view
        </p>
      ),
    },
    { title: "Status", dataIndex: "Status", key: "Status" },
  ];

  return (
    <div className="PatientInfo_Body_Table">
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 8, size: "small" }}
        loading={PatientViewLoader}
      />
    </div>
  )
}

export default Sessions
