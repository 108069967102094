import React, { useState, useEffect } from "react";
import "./DashboardHelpSupport.scss";
import { Button, Icon, List, Row, Col, Avatar, Divider, message } from "antd";
import CustomInput from "./../../../Common/custom-input";
import CustomUpload from "./../../../Common/custom-upload";
import moment from "moment";
import RichTextEditor, { stateToHTML } from "react-rte";
import DashboardLayout from "./../DashboardLayout";
import axios from "axios";
import { baseUrl } from "../../../config1";
import GetlocalStorage from "../../../Common/localStorageHelper/getLocalStorage";

const initialTicketinfodata = {
  category: "",
  imgUrls: [],
  senderId: "",
  userName: "",
  userEmail: "",
};

const DashboardHelpSupport = () => {
  const [HelpSupport, setHelpSupport] = useState("HelpSupportPage");
  const [ticketsugget, setticketsugget] = useState();
  const [TicketRaisefrom, setTicketRaisefrom] = useState({
    name: "",
    category: "",
  });
  const [TicketfetchStatus, setTicketfetchStatus] = useState(false);
  const [ticketmessage, setTicketmessage] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [TicketList, setTicketList] = useState();
  const [TicketStatus, setTicketStatus] = useState(false);
  const [spin, setspin] = useState({
    updatefile: false,
    currentfile: false,
  });
  const [TicketConservation, setTicketConservation] = useState();
  const [TicketRaiseinfo, setTicketRaiseinfo] = useState(initialTicketinfodata);
  
  let getlocalStorage = GetlocalStorage();
  const userid = getlocalStorage?.Doctorid;
  const userEmail = getlocalStorage?.mdemail;
  const UserName = getlocalStorage?.fullName;
  const { name, category } = TicketRaisefrom;

  useEffect(() => {
    setTicketRaiseinfo({
      ...TicketRaiseinfo,
      senderId: userid,
      userName: userEmail,
      userEmail: userEmail,
    });
  }, []);

  const CATEGORY = [
    "Appointments",
    "Payments related",
    "Cancellation or refund",
    "Doctor related",
    "Issues with service",
    "Others",
  ];
  const HelpCategoryData = [{ name: "TeleHealth", category: CATEGORY }];

  const CategoryQuestions = [
    "Cant see appointment details",
    "Money Dedcuted but appointment failed",
    "Want to update slot bookings",
    "I have booked wrong time want to change time of appointment",
    "Want to cancel appointment but its not happening",
  ];

  const Axiosinstance = axios.create({
    baseURL: baseUrl,
    headers: {
      "content-type": "application/json",
    },
    responseType: "json",
  });

  const handleRaiseTicket = (name, category) => {
    setTicketRaisefrom({
      name,
      category,
    });
    setHelpSupport("RaiseTickets");
    setTicketRaiseinfo({
      ...TicketRaiseinfo,
      category: ` ${category} (${name})`,
    });
  };

  const handleSuggestion = (item) => {
    // setTicketRaiseinfo({
    //   ...TicketRaiseinfo,
    //   ticket: item,
    // });
    setTicketmessage(RichTextEditor.createEmptyValue());
    setticketsugget(item);
  };

  useEffect(() => {
    fetchTicketsList();
  }, [TicketfetchStatus]);

  useEffect(() => {
    if (TicketConservation?._id) {
      const fiter = TicketList.filter(
        (item) => item._id === TicketConservation?._id
      );
      setTicketConservation(fiter[0]);
    }
  }, [TicketList]);

  const handleTicketSumbits = async () => {
    const data = {
      ...TicketRaiseinfo,
      tickets: [
        {
          message: ticketsugget
            ? `<p>${ticketsugget}</p>`
            : ticketmessage.toString("html"),
          senderImg: getlocalStorage?.profilePhoto,
          senderName: UserName,
        },
      ],
    };
    try {
      const res = await Axiosinstance.post("tickets/v1.0/addTicket", data);
      if (res.status === 200) {
        setTicketRaiseinfo(initialTicketinfodata);
        setTicketmessage(RichTextEditor.createEmptyValue());
        setTicketfetchStatus((prestate) => !prestate);
        setticketsugget();
        setHelpSupport("HelpSupportPage");
      }
    } catch (error) {}
  };

  const fetchTicketsList = async () => {
    try {
      const res = await Axiosinstance.get(`tickets/v1.0/getTickets/${userid}`);
      setTicketList(res.data.data);
    } catch (error) {}
  };

  const handleMessage = (value) => {
    setTicketmessage(value);
    setticketsugget();
  };

  const { ticket, imgUrls } = TicketRaiseinfo;

  const toolbarConfig = {
    display: ["none"],
  };

  const handleTicketdetails = (ticketinfo, val) => {
    setTicketConservation(ticketinfo);
    setHelpSupport(val);
  };

  console.log(ticketmessage.toString("html"), ticketmessage);

  const handleUpdateConversion = async () => {
    // if(ticketmessage.toString("html")=="<p><br></p>"){
    const data = {
      tickets: [
        {
          message: ticketmessage.toString("html"),
          senderImg: getlocalStorage?.profilePhoto,
          senderName: UserName,
        },
      ],
    };
    try {
      const res = await Axiosinstance.put(
        `tickets/v1.0/updateTicket/${TicketConservation?._id}`,
        data
      );
      if (res.status === 200) {
        setTicketmessage(RichTextEditor.createEmptyValue());
        setTicketfetchStatus((prestate) => !prestate);
        // setHelpSupport("HelpSupportPage");
      }
    } catch (error) {}
    // }else{
    //   message.info("message can't blank")
    // }
  };

  const handlecloseTicket = async () => {
    try {
      const res = await Axiosinstance.put(
        `tickets/v1.0/updateTicketStatus/${TicketConservation?._id}`
      );
      if (res.status === 200) {
        setTicketfetchStatus((prestate) => !prestate);
        setTicketStatus(false);
      }
    } catch (error) {}
  };

  const handleDeletefile = (index) => {
    const filter = TicketRaiseinfo.imgUrls.filter((item, i) => i !== index);
    setTicketRaiseinfo((prevState) => ({
      ...prevState,
      imgUrls: filter,
    }));
  };
  return (
    <DashboardLayout>
      {HelpSupport === "HelpSupportPage" && (
        <div className="DashboardHelpSupport-Container">
          <h1>
            How can we help you?
            <Button
              onClick={() => setHelpSupport("TicketsList")}
              type="primary"
            >
              TICKETS
            </Button>
          </h1>
          <CustomInput
            placeholder="Write a question or problems"
            prefix={<Icon type="search" />}
          />
          <img
            src="https://ubstaticimgs.s3.ap-south-1.amazonaws.com/Help%26Support.svg"
            alt="/"
          />
          <div className="DashboardHelpSupport-Content">
            <h3 className="HelpSupport-SubTitle">
              Popular Questions &nbsp;
              <Icon type="down" />
            </h3>
            <div className="PopularQuestions">
              {Array(7)
                .fill(0)
                .map(() => (
                  <div className="Questions">
                    <p>How to create an issue</p>
                  </div>
                ))}
            </div>
            <h3 className="HelpSupport-SubTitle">Help by category</h3>
            <List
              grid={{ gutter: [104, 32], lg: 3, xxl: 4 }}
              dataSource={HelpCategoryData}
              renderItem={(item) => (
                <List.Item>
                  <div className="CategoryTitle">
                    <img
                      src={require("../../../Assets/dashboard_telehealth_icon.svg")}
                      alt="/"
                    />
                    <h3>{item.name}</h3>
                  </div>
                  {item.category.map((val) => (
                    <p onClick={() => handleRaiseTicket(item.name, val)}>
                      {val}
                    </p>
                  ))}
                </List.Item>
              )}
            />
          </div>
        </div>
      )}

      {HelpSupport === "RaiseTickets" && (
        <div className="RaiseTickets-Container">
          <h1>
            Category : {category} ( {name} )
          </h1>
          <div className="CategoryQuestions">
            {CategoryQuestions.map((item) => (
              <div
                className="Questions"
                style={{ backgroundColor: ticketsugget === item && "#4fb8b6" }}
                onClick={() => handleSuggestion(item)}
              >
                <p
                  style={
                    ticketsugget === item ? { color: "#fff", opacity: "1" } : {}
                  }
                >
                  {item}
                </p>
              </div>
            ))}
          </div>
          <div className="UploadBody">
            <div className="UploadContent">
              <div className="UploadDetails">
                <p>Upload If any : </p>
                <CustomUpload
                  name="avatar"
                  listType={"picture-card"}
                  className="Image-Uploader1"
                  setUploadstate={setTicketRaiseinfo}
                  setspin={setspin}
                  support
                  uploadkey={"imgUrls"}
                >
                  <Icon type="plus" />
                </CustomUpload>
              </div>
              <div className="UploadIMGs">
                {imgUrls.map((item, i) => {
                  let ImgType = item.substring(item.lastIndexOf(".") + 1);

                  return (
                    <div className="IMGs">
                      {ImgType !== "pdf" ? (
                        <img src={item} />
                      ) : (
                        <Icon type="file" />
                      )}
                      <Icon type="delete" onClick={() => handleDeletefile(i)} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <RichTextEditor
            className="Rich-Texteditor"
            value={ticketmessage}
            onChange={handleMessage}
            toolbarConfig={toolbarConfig}
          />
          <div className="BtnSetup">
            <Button onClick={handleTicketSumbits} type="primary">
              Submit
            </Button>
            <Button onClick={() => setHelpSupport("HelpSupportPage")}>
              Cancel
            </Button>
          </div>
        </div>
      )}

      {HelpSupport === "TicketsList" && (
        <div className="TicketsList-Container">
          <div
            className="BackRoute"
            onClick={() => setHelpSupport("HelpSupportPage")}
          >
            <Icon type="arrow-left" />
            <Icon type="minus" />
            <h1>My Tickets</h1>
          </div>
          <List
            dataSource={TicketList}
            pagination={
              TicketList?.length > 0 ? { pageSize: 4, size: "small" } : false
            }
            renderItem={(item) => (
              <List.Item>
                <div
                  className="TicketsList-Body"
                  onClick={() => handleTicketdetails(item, "MyTicketDetails")}
                  style={{
                    background:
                      item.status === "Closed" ? "#CD303019" : "#f3f8ff",
                  }}
                >
                  <div>
                    <h3 style={{ opacity: "0.5" }}>Ticket number</h3>
                    <h3>{item.ticketNo}</h3>
                  </div>
                  <div>
                    <h3 style={{ opacity: "0.5" }}>Date of Raised</h3>
                    {/* <h3>{item.ticketNo}</h3> */}
                    <h3>
                      {moment(new Date(item.createdAt)).format("MM/DD/YYYY")}
                    </h3>
                  </div>
                  <div>
                    <h3 style={{ opacity: "0.5" }}>Category/Section</h3>
                    <h3>{item.category}</h3>
                  </div>
                  <div>
                    <h3 style={{ opacity: "0.5" }}>Resolved by</h3>
                    <h3>Sreenivas ( Cust.Support )</h3>
                  </div>
                  <div>
                    <h3 style={{ opacity: "0.5" }}>Status</h3>
                    <h3
                      style={{
                        color: item.status === "Closed" ? "#CE3030" : "#4fb8b6",
                      }}
                    >
                      {item.status}
                    </h3>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
      )}

      {HelpSupport === "MyTicketDetails" && (
        <div className="MyTicketDetails-Container">
          <div
            className="BackRoute"
            onClick={() => setHelpSupport("TicketsList")}
          >
            <Icon type="arrow-left" />
            <Icon type="minus" />
            <h1>My Tickets</h1>
          </div>
          <Row>
            <Col lg={8}>
              <div className="MyTicketList-Container">
                {TicketList?.map((item) => (
                  <div
                    className="MyTicketList-Body"
                    onClick={() => handleTicketdetails(item, "MyTicketDetails")}
                    style={
                      item.status === "Closed"
                        ? {
                            background: "#fdeaea",
                            borderColor:
                              TicketConservation._id === item._id && "#CE3030",
                          }
                        : {
                            background: "#f3f8ff",
                            borderColor:
                              TicketConservation._id === item._id && "#4fb8b6",
                          }
                    }
                  >
                    <div className="TicketInfo">
                      <div>
                        <h5 style={{ opacity: "0.5" }}>Ticket number</h5>
                        <h5>{item.ticketNo}</h5>
                      </div>
                      <h6>
                        {moment(new Date(item.createdAt)).format(
                          "MM/DD/YYYY"
                        )}
                      </h6>
                    </div>
                    <div className="TicketInfo Info2">
                      <h4>{item.category}</h4>
                      <h3
                        style={{
                          color:
                            item.status === "Closed" ? "#CE3030" : "#4fb8b6",
                        }}
                      >
                        <span
                          style={{
                            background:
                              item.status === "Closed" ? "#CE3030" : "#4fb8b6",
                          }}
                          className="StatusDot"
                        ></span>
                        {item.status}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col lg={16}>
              <div className="MyTicket-Content">
                <div className="MyTicketIssue">
                  <h3>{TicketConservation?.category}</h3>
                  <div>
                    <p>Ticket {TicketConservation?.ticketNo}</p>
                    <h3
                      className="Status"
                      style={{
                        color:
                          TicketConservation?.status === "Closed"
                            ? "#CE3030"
                            : "#4fb8b6",
                      }}
                    >
                      {TicketConservation?.status}
                    </h3>
                  </div>
                </div>

                <div
                  className="Message-Container"
                  style={{
                    height:
                      TicketConservation?.status === "Opened" &&
                      TicketStatus &&
                      "60vh",
                  }}
                >
                  {TicketConservation?.tickets.map((item) => (
                    <>
                      <div className="MessageDetails">
                        <div className="UserInfo">
                          {item.senderImg !== "null" &&
                          item.senderImg !== "undefined" ? (
                            <Avatar src={item.senderImg} />
                          ) : (
                            <Avatar className="NoProfileImg">
                              {item.senderName
                                .replace(/[^a-zA-Z0-9]/g, "")
                                .charAt(0)}
                            </Avatar>
                          )}
                          <h3> {item.senderName}</h3>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.message,
                          }}
                        />
                      </div>
                      <div style={{ position: "relative" }}>
                        <Divider />
                      </div>
                    </>
                  ))}
                </div>

                <div
                  className="BtnSetup"
                  style={{ display: TicketStatus && "none" }}
                >
                  {TicketConservation?.status === "Opened" && !TicketStatus && (
                    <Button
                      type="primary"
                      onClick={() => setTicketStatus(true)}
                    >
                      Click here to start conversation
                    </Button>
                  )}
                  {TicketConservation?.status === "Closed" && !TicketStatus && (
                    <Button
                      type="primary"
                      style={
                        TicketConservation?.status === "Closed"
                          ? {
                              background: "#FAEAEA",
                              color: "#CE3030",
                              border: "none",
                            }
                          : {}
                      }
                    >
                      Ticket has been closed
                    </Button>
                  )}
                </div>

                {TicketConservation?.status === "Opened" && TicketStatus && (
                  <>
                    <div className="MessageBox">
                      <RichTextEditor
                        className="Rich-Texteditor"
                        value={ticketmessage}
                        onChange={handleMessage}
                        toolbarConfig={toolbarConfig}
                      />
                      <img
                        src={require("../../../Assets/paperplaneicon.png")}
                        alt="/"
                        onClick={handleUpdateConversion}
                      />
                    </div>
                    <div className="CloseTicketBTN">
                      <Button onClick={handlecloseTicket} type="primary">
                        Close Ticket
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </DashboardLayout>
  );
};

export default DashboardHelpSupport;
