export const setLoginDataLocalStorage = (data) => {
  localStorage.setItem("Doctorid", data._id);
  localStorage.setItem("specialtiesId", data.specialtiesId);
  localStorage.setItem("x-token", data.token);
  data.isProfileCompleted && localStorage.setItem("isMdlogged", true);
  localStorage.setItem("profilePhoto", data.profilePhoto);
  localStorage.setItem("auth", data.auth);
  localStorage.setItem("specialtiesName", data.specialtiesName);
  localStorage.setItem("fullName", data.fullName);
  localStorage.setItem("mdemail", data.email);
  localStorage.setItem("profilePhoto", data.profilePhoto);
};

export const setProfilePhoto = (profilePhoto) => {
  localStorage.setItem("profilePhoto", profilePhoto);
};

export const setIsMdlogged = (value) => {
  localStorage.setItem("isMdlogged", value);
};
