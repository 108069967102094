import {
  Create_Appointment_Request,
  Get_Appointment_Request,
  Update_Appointments_Request,
  Get_Schedule_Request,
} from "./CreateAppointmentActionTypes";
import Axiosinstance from "../../config";
import { message } from "antd";
import GetlocalStorage from "../../Common/localStorageHelper/getLocalStorage";

const create = (body) =>
  Axiosinstance.post("schedule/v1.0/createSchedule", body);

export const Appointment_Create_Action =
  (data, routercall, handleLoader) => async (dispatch) => {
    try {
      handleLoader(true);
      const res = await create(data);
      console.log("res", res);
      if (res.status === 200) {
        dispatch(AppointmentCreate(res.data));
        routercall();
        handleLoader(false);
        message.success("Schedule Updated");
      } else if (res.status === 404) {
        console.log("resstatus", res.data.message);
        routercall(res.data.message);
        handleLoader(false);
        message.success(res.data.message);
      }
    } catch (error) {
      console.log("Appointment_Create_Action", error);
    }
  };
let getlocalStorage = GetlocalStorage();
const get_appointments = (data) =>
  Axiosinstance.post(
    "appointment/v1.0/getBookings/" + getlocalStorage.Doctorid,
    data
  );

export const getAppointments_Action =
  (data, routercall, handleLoader) => async (dispatch) => {
    let res;
    try {
      res = await get_appointments(data);
      if (res.data.status === 200) {
        dispatch(GetAppointment(res.data.data));
        routercall();
        handleLoader(false);
      } else {
        routercall(res.data.message);
        handleLoader(false);
        return [];
      }
    } catch (error) {
      console.log("get appointments error", error);
    }
  };

const cancel_appointments = (data) =>
  Axiosinstance.post("appointment/v1.0/cancleAppointments", data);

// const Profile_update_User = (id,data) =>Axiosinstance.get(`${Profile_update_Userapi}/${id}`,data);
export const cancelAppointments_Action =
  (data, routercall, handleLoader, handleModel) => async (dispatch) => {
    let res;
    try {
      res = await cancel_appointments(data);
      if (res.data.status === 200) {
        routercall(res.data.message);
        handleLoader(false);
        handleModel(false);
      } else {
        routercall(res.data.message);
        handleLoader(false);
        handleModel(false);
        return [];
      }
    } catch (error) {
      console.log("get appointments error", error);
    }
  };

const cancel_appointment = (data) =>
  Axiosinstance.put("appointment/v1.0/cancleAppointment/" + data._id, data);

// const Profile_update_User = (id,data) =>Axiosinstance.get(`${Profile_update_Userapi}/${id}`,data);
export const cancelSingleAppointment_Action =
  (data, routercall, handleLoader, handleSingleCancleModel, handleSlots1) =>
  async (dispatch) => {
    let res;
    try {
      res = await cancel_appointment(data);
      if (res.data.status === 200) {
        routercall(res.data.message);
        handleLoader(false);
        handleSingleCancleModel(false);
        handleSlots1();
      } else {
        routercall(res.data.message);
        handleLoader(false);
        handleSingleCancleModel(false);
        handleSlots1();
        return [];
      }
    } catch (error) {
      console.log("get appointments error", error);
    }
  };

const update = (body, id) =>
  Axiosinstance.post("schedule/v1.0/updateSchedule/" + id, body);

export const Appointment_Update_Action =
  (data, id, routercall, handleLoader) => async (dispatch) => {
    try {
      handleLoader(true);
      const res = await update(data, id);
      console.log("res", res);
      if (res.status === 200) {
        dispatch(AppointmentUpdate(res.data));
        message.success(res.data.message);
        routercall();
        handleLoader(false);
      } else if (res.status === 404) {
        console.log("resstatus", res.data.message);
        routercall(res.data.message);
        message.error(res.data.message);
        handleLoader(false);
      }
    } catch (error) {
      console.log("Appointment_Update_Action", error);
    }
  };

const schedule = (id) => Axiosinstance.get("schedule/v1.0/getSchedules/" + id);

export const getSchedule_Action =
  (id, routercall, handleLoader) => async (dispatch) => {
    try {
      handleLoader(true);
      const res = await schedule(id);
      console.log("res", res);
      if (res.status === 200) {
        dispatch(getSchedule(res.data.data));
        routercall();
        handleLoader(false);
      } else if (res.status === 404) {
        console.log("resstatus", res.data.message);
        routercall(res.data.message);
        handleLoader(false);
      }
    } catch (error) {
      console.log("Appointment_Update_Action", error);
    }
  };

const AppointmentCreate = (Providerinfo) => ({
  type: Create_Appointment_Request,
  payload: Providerinfo,
});

const GetAppointment = (Providerinfo) => ({
  type: Get_Appointment_Request,
  payload: Providerinfo,
});

const AppointmentUpdate = (Providerinfo) => ({
  type: Update_Appointments_Request,
  payload: Providerinfo,
});

const getSchedule = (Providerinfo) => ({
  type: Get_Schedule_Request,
  payload: Providerinfo,
});
