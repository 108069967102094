import React, { useState } from "react";
import pin from "../Assets/pin.svg";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

const AutoLocation = ({
  setProfileInfo,
  Addressdata,
  placeholder,
  className,
  setEditResponse,
  EditResponse,
}) => {
  const [address, setAddress] = useState(null);

  const handleItemClicked = async (place) => {
    console.log("place,,,,,,,,", place);
    const results = await geocodeByAddress(place.description);
    const latLng = await getLatLng(results[0]);
    const city = place?.terms.slice(-3)[0]?.value;
    const statename = place?.terms.slice(-2)[0]?.value;
    const countryName = place?.terms.slice(-1)[0]?.value;
    const placeZipcode = results[0].address_components.slice(-1)[0].short_name;
    const { lat, lng } = latLng;
    // const streetname =
    // place?.terms.slice(-4)[0]?.value !== place?.terms.slice(-3)[0]?.value
    //   ? place?.terms.slice(-4)[0]?.value
    //   : "";

    console.log("placeZipcode", typeof placeZipcode, placeZipcode);
    setProfileInfo((prevState) => ({
      ...prevState,
      zip: placeZipcode && placeZipcode.length > 4 ? placeZipcode : "",
      address: {
        ...prevState.address,
        street:place.description,
        city: city,
        state: statename,
        country: countryName,
        zipCode:  placeZipcode && placeZipcode.length > 4 ? placeZipcode : "",
      },
    location: {
        coordinates: [lat, lng],
      },
    }));
    setEditResponse({
      ...EditResponse,
      zip: placeZipcode && placeZipcode.length > 4 ? placeZipcode : "",
      address: {
        ...EditResponse.address,
        street:place.description,
        city: city,
        state: statename,
        country: countryName,
        zipCode:  placeZipcode && placeZipcode.length > 4 ? placeZipcode : "",
      },
    location: {
        coordinates: [lat, lng],
      },
    });
  };

  const { street } = Addressdata?.address ?? {};
  console.log("street", Addressdata?.address);

  return (
    <div className={className ? className : "AutocompletePlace-container"}>
      <GooglePlacesAutocomplete
        placeholder={placeholder ? placeholder : "Enter your Location"}
        apiKey="AIzaSyCzj_ngf9JwrP93BG8jNoeFMfNv4ik6n5A"
        value={address}
        onChange={setAddress}
        onSelect={handleItemClicked}
        initialValue={street ? street : null}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["us", "in"],
          },
        }}
      />
      <img className="PinIMG" src={pin} alt="pinImage" />
    </div>
  );
};

export default AutoLocation;
