// loaderUtils.js

// Function to show the loader
/**
 * DashboardLayout Comonent
 * @param {any} props The first number.
//  * @returns {jsx} The jsx.
 */
export const showLoader = () => {
    const loader = document.getElementById('loader');
    if (loader) {
        loader.style.display = 'flex';
    }
};

// Function to hide the loader
/**
 * DashboardLayout Comonent
 * @param {any} props The first number.
//  * @returns {jsx} The jsx.
 */
export const hideLoader = () => {
    const loader = document.getElementById('loader');
    if (loader) {
        loader.style.display = 'none';
    }
};
