import { Alert } from "antd";
import Axiosinstance from "../../config";
import { Fetch_Appointments_Request, Fetch_Appointments_Count_Request, Fetch_Todays_Appointments_Request, Fetch_Consultation_Request } from "./AppointmentsActionTypes";
import { Update_Appointments_Request } from "./AppointmentsActionTypes";

const appointmentsCountApi = "appointment/v1.0/getcount"
const AppointmentsCount_fetch = (id, chr, cmin) => Axiosinstance.get(`${appointmentsCountApi}/${id}?chr=${chr}&cmin=${cmin}`);
export const AppointmentsCount_fetch_Action = (id, routercall, handleLoader, chr, cmin) => async (dispatch) => {
  let res;
  handleLoader(true)
  try {
    res = await AppointmentsCount_fetch(id, chr, cmin);
    if (res.status === 200) {
      dispatch(Appointments_Count_Request(res.data));
      handleLoader(false)
    } else if (res.status === 401) {
      routercall("/")
    }
  } catch (error) {
    console.log("Appointments__fetch_Request-error", error);
  }
}
const appointmentapi = "appointment/v1.0/getAppointments"
const Appointments_fetch = (id, type, chr, cmin) => Axiosinstance.get(`${appointmentapi}/${type}/${id}?chr=${chr}&cmin=${cmin}`);
export const Appointments_fetch_Action = (id, type, routercall, handleLoader, chr, cmin) => async (dispatch) => {
  let res;
  handleLoader(true)
  try {
    res = await Appointments_fetch(id, type, chr, cmin);
    if (res.status === 200) {
      dispatch(Appointments_fetch_Request(res.data));
      handleLoader(false)
    } else if (res.status === 401) {
      routercall("/")
    }
  } catch (error) {
    console.log("Appointments__fetch_Request-error", error);
  }
}

export const Todays_Appointments_fetch_Action = (id, type, routercall, handleLoader, chr, cmin) => async (dispatch) => {
  console.log("fffffhjxvgutdsgfub", chr, cmin)
  let res;
  handleLoader(true)
  try {
    res = await Appointments_fetch(id, type,chr?.trim(), cmin);
    console.log("resresres",res)
    if (res.status === 200) {
      dispatch(TodaysAppointments_fetch_Request(res.data));
      handleLoader(false)
    } else if (res.status === 401) {
      routercall("/")
    }
  } catch (error) {
    console.log("Appointments__fetch_Request-error", error);
  }
}

const UpdateAppointments_Api = "appointment/v1.0/updateAppointment"
const Appointments_update = (id, data) => Axiosinstance.post(`${UpdateAppointments_Api}/${id}`, data);
export const Appointments_update_Action = (id, data, routercall, handleLoader) => async (dispatch) => {
  let res;
  handleLoader(true)
  try {
    res = await Appointments_update(id, data);
    if (res.status === 200) {
      dispatch(Appointments_update_Request(res.data.data));
      routercall(false)
      handleLoader(false)
    } else if (res.status === 401) {
      routercall("/")
      handleLoader(false)
    }
  } catch (error) {
    console.log("Appointments__fetch_Request-error", error);
  }
}

/* Consultation Api */
const consultationsApi = "appointment/v1.0/getconsultation"
const Consultation_fetch = (id, chr, cmin) => Axiosinstance.get(`${consultationsApi}/${id}?chr=${chr.trim()}&cmin=${cmin}`);
export const Consultation_fetch_Action = (id, routercall, handleLoader, chr, cmin) => async (dispatch) => {
  let res;
  handleLoader(true)
  try {
    res = await Consultation_fetch(id, chr, cmin);
    if (res.status === 200) {
      dispatch(Consultation_Request(res.data));
      handleLoader(false)
    } else if (res.status === 401) {
      routercall("/")
    }
  } catch (error) {
    console.log("Appointments__fetch_Request-error", error);
  }
}


const Appointments_fetch_Request = (Appointmentsinfo) => ({
  type: Fetch_Appointments_Request,
  payload: Appointmentsinfo,
});
const TodaysAppointments_fetch_Request = (Appointmentsinfo) => ({
  type: Fetch_Todays_Appointments_Request,
  payload: Appointmentsinfo,
});
const Appointments_update_Request = (Appointmentsinfo) => ({
  type: Update_Appointments_Request,
  payload: Appointmentsinfo,
});
const Appointments_Count_Request = (Appointmentsinfo) => ({
  type: Fetch_Appointments_Count_Request,
  payload: Appointmentsinfo,
});
const Consultation_Request = (Appointmentsinfo) => ({
  type: Fetch_Consultation_Request,
  payload: Appointmentsinfo,
});