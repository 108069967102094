import { Icon, Input, Upload, Spin, message } from "antd";
import React, { useState, useEffect } from "react";
import CustomInput from "./../../../../../Common/custom-input";
import S3 from "react-aws-s3";
import CustomUpload from "../../../../../Common/custom-upload";

const { TextArea } = Input;

const EditAccomplishment = ({
  Accomplishmentdata,
  setAccomplishment,
  AddProtext,
  setAddProtext,
  AddAwardstext,
  setAwardstext,
  AddCertifile,
  setAddCertifile,
  setBlurCheck,
  BlurCheck,
  errors,
  validate,
  setEditResponse,
  EditResponse,
  RemovedProMembership,
  setRemovedProMembership,
  RemovedAwards,
  setRemovedAwards,
}) => {
  const [spin, setspin] = useState({
    updatefile: false,
    currentfile: false,
  });
  const [currentfile, setcurrentfile] = useState();
  const [filedata, setfiledata] = useState();
  const [AddProinput, setAddProinput] = useState();
  const [AddAwardsinput, setAwardsinput] = useState();
  const [AddCertiinput, setAddCertiinput] = useState();
  const [intialCertificationInput, setintialCertificationInput] =
    useState(false);
  const [intialProMembershipInput, setintialProMembershipInput] =
    useState(false);
  const [intialAwardsInput, setintialAwardsInput] = useState(false);
  const { certification, profMembership, awards } = Accomplishmentdata ?? [];
  console.log("Accomplishmentdata", Accomplishmentdata);

  useEffect(() => {
    if (certification?.length !== 0) {
      setAddCertiinput(true);
    }
    if (profMembership?.length !== 0) {
      setAddProinput(true);
    }
    if (awards?.awards !== 0) {
      setAwardsinput(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleupload = (info, key) => {
    if (key === "AddCertifile") {
      setspin({
        ...spin,
        currentfile: true,
      });
    } else {
      setspin({
        ...spin,
        updatefile: true,
      });
    }

    setcurrentfile(key);
    if (filedata && filedata.size <= 5000000) {
      const newFileName = filedata?.name.replace(/\..+$/, "");
      const config = {
        bucketName: "ub-provider-image-uploader",
        dirName: "host" /* optional */,
        region: "us-west-1",
        accessKeyId: "AKIAW7CW6QVRSTEZBUOO",
        secretAccessKey: "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",
      };
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(filedata, newFileName).then((data) => {
        if (data.status === 204) {
          if (key === "AddCertifile") {
            setAddCertifile(data.location);
            setspin({
              ...spin,
              currentfile: false,
            });
            setcurrentfile();
          } else {
            let newArr = { ...Accomplishmentdata };
            newArr.certification[key] = data.location;
            setAccomplishment(newArr);
            const editarray = { ...EditResponse, certification };
            editarray.certification[key] = data.location;
            setEditResponse(editarray);
            setspin({
              ...spin,
              updatefile: false,
            });
            setcurrentfile();
          }
        } else {
          console.log("fail");
        }
      });
    } else {
      message.error(`${filedata.name} is too large Maximum file size is 5MB`);
      setspin({
        ...spin,
        currentfile: false,
      });
      setspin({
        ...spin,
        updatefile: false,
      });
    }
  };
  const fileUploadRequest = ({ file, onSuccess }) => {
    setfiledata(file);
  };

  const handletextChange = (e) => {
    const { name, value } = e.target;
    if (name === "AddProtext") {
      setAddProtext(value);
    } else if (name === "AddAwardstext") setAwardstext(value);
  };

  const handleprofMembershipTextChange = (i, event) => {
    console.log("event.target.value", i, event.target.value);
    let newArr = { ...Accomplishmentdata };
    newArr.profMembership[i] = event.target.value;
    setAccomplishment(newArr);
    const editarray = { ...EditResponse, profMembership };
    editarray.profMembership[i] = event.target.value;
    setEditResponse(editarray);
    if (!newArr.profMembership[i]) {
      setRemovedProMembership([...RemovedProMembership, i]);
    } else {
      const filter = RemovedProMembership.filter((item) => item !== i);
      setRemovedProMembership(filter);
    }
  };

  const handleAwardsTextChange = (i, event) => {
    console.log("event.target.value", i, event.target.value);
    let newArr = { ...Accomplishmentdata };
    newArr.awards[i] = event.target.value;
    setAccomplishment(newArr);
    const editarray = { ...EditResponse, awards };
    editarray.awards[i] = event.target.value;
    setEditResponse(editarray);
    if (!newArr.awards[i]) {
      setRemovedAwards([...RemovedAwards, i]);
    } else {
      const filter = RemovedAwards.filter((item) => item !== i);
      setRemovedAwards(filter);
    }
  };
  const handleRemovedItems = (i, val) => {
    console.log("val", val);
    let newArr = { ...Accomplishmentdata };
    if (val === "ProMemberships") {
      const filter = newArr.profMembership.filter((item, index) => index !== i);
      setAccomplishment((prevState) => ({
        ...prevState,
        profMembership: filter,
      }));
      setEditResponse({
        ...EditResponse,
        profMembership: filter,
      });
      setintialProMembershipInput(true);
    } else if (val === "Awards") {
      const filter = newArr.awards.filter((item, index) => index !== i);
      setAccomplishment((prevState) => ({
        ...prevState,
        awards: filter,
      }));
      setEditResponse({
        ...EditResponse,
        awards: filter,
      });
      setintialAwardsInput(true);
    } else if (val === "Cert") {
      const filter = newArr.certification.filter((item, index) => index !== i);
      setAccomplishment((prevState) => ({
        ...prevState,
        certification: filter,
      }));
      setEditResponse({
        ...EditResponse,
        certification: filter,
      });

      setintialCertificationInput(true);
    }
  };

  const handleAddfile = () => {
    if (certification?.length > 0) {
      setAddCertiinput(true);
      if (AddCertifile) {
        setAddCertiinput(false);
        setAccomplishment({
          ...Accomplishmentdata,
          certification: [...certification, AddCertifile],
        });

        setEditResponse({
          ...EditResponse,
          certification: [...certification, AddCertifile],
        });
        setAddCertifile();
        setAddCertiinput(true);
      }
    } else {
      if (AddCertifile) {
        setAccomplishment({
          ...Accomplishmentdata,
          certification: [...certification, AddCertifile],
        });
        setEditResponse({
          ...EditResponse,
          certification: [...certification, AddCertifile],
        });
        setAddCertifile();
        setAddCertiinput(true);
      }
    }
  };

  const handleAddText = (val) => {
    if (val === "profMembership") {
      if (profMembership.length > 0) {
        console.log("sssssshello");
        setAddProinput(true);
        if (AddProtext) {
          setAddProinput(false);
          setAccomplishment({
            ...Accomplishmentdata,
            profMembership: [...profMembership, AddProtext],
          });
          setEditResponse({
            ...EditResponse,
            profMembership: [...profMembership, AddProtext],
          });
          setAddProtext();
          setAddProinput(true);
        }
      } else {
        if (AddProtext) {
          setAccomplishment({
            ...Accomplishmentdata,
            profMembership: [...profMembership, AddProtext],
          });
          setEditResponse({
            ...EditResponse,
            profMembership: [...profMembership, AddProtext],
          });
          setAddProtext();
          setAddProinput(true);
        }
      }
    } else if (val === "Awards") {
      if (awards.length > 0) {
        setAwardsinput(true);
        if (AddAwardstext) {
          setAwardsinput(false);
          setAccomplishment({
            ...Accomplishmentdata,
            awards: [...awards, AddAwardstext],
          });
          setEditResponse({
            ...EditResponse,
            awards: [...awards, AddAwardstext],
          });
          setAwardstext();
          setAwardsinput(true);
        }
      } else {
        if (AddAwardstext) {
          setAccomplishment({
            ...Accomplishmentdata,
            awards: [...awards, AddAwardstext],
          });
          setEditResponse({
            ...EditResponse,
            awards: [...awards, AddAwardstext],
          });
          setAwardstext();
          setAwardsinput(true);
        }
      }
    }
  };
console.log("spinn",spin.updatefile)
  return (
    <>
      <div className="Education-Accomplishments-Title Accountinfo-SubEdit">
        <h2>Accomplishments</h2>
      </div>
      <div className="Education-Accomplishments-Details">
        <h5 className="NoTextarea">certification</h5>
        <div className="Inputs1">
          {certification?.length > 0
            ? certification.map((item, i) => (
                <div className="Inner-Field-Details">
                  <CustomInput
                    className="InputCustom"
                    placeholder="Type or Upload"
                    value={item.substring(item.lastIndexOf("/") + 1)}
                    suffix={
                      !spin.updatefile || currentfile !== i ? (
                        <CustomUpload
                          setUploadstate={setAccomplishment}
                          setspin={setspin}
                          accept={
                            ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          }
                          uploadfrom={"MdsettingProcess"}
                          uploadkey={i}
                          setcurrentfile={setcurrentfile}
                          EditResponse={EditResponse}
                          certification={certification}
                          settingsDynamic
                          setAddCertifile={setAddCertifile}
                          uploaddata={Accomplishmentdata}
                          setEditResponse={setEditResponse}
                          // email={email}
                        >

                        <Icon className="UPLOAD" type="cloud-upload" />
                        </CustomUpload>
                      ) : (
                        currentfile === i && (
                          <Spin
                            className="AccountInfoSPIN"
                            spinning={spin.updatefile}
                         
                          />
                        )
                      )
                    }
                  />
                  <Icon
                    type="minus-circle"
                    theme="filled"
                    onClick={() => handleRemovedItems(i, "Cert")}
                  />
                  {/* <p className="ERROR1">Field cant be blank</p> */}
                </div>
              ))
            : !intialCertificationInput && (
                <CustomInput
                  className="InputCustom"
                  placeholder="Type or Upload"
                  value={AddCertifile?.substring(
                    AddCertifile?.lastIndexOf("/") + 1
                  )}
                  onBlur={(event) => {
                    setBlurCheck({ ...BlurCheck, AddCertifile: true });
                    validate();
                  }}
                  suffix={
                    !spin.currentfile ? (
                      <CustomUpload
                        setUploadstate={setAccomplishment}
                        setspin={setspin}
                        accept={
                          ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        }
                        uploadfrom={"MdsettingProcess"}
                        uploadkey={"AddCertifile"}
                        setcurrentfile={setcurrentfile}
                        EditResponse={EditResponse}
                        certification={certification}
                        settingsDynamic
                        setAddCertifile={setAddCertifile}
                        uploaddata={Accomplishmentdata}
                        setEditResponse={setEditResponse}
                        // email={email}
                      >
                      <Icon className="UPLOAD" type="cloud-upload" />
                      </CustomUpload>
                    ) : (
                      <Spin
                        className="AccountInfoSPIN"
                        spinning={spin.currentfile}
                       
                      />
                    )
                  }
                />
              )}
          {AddCertiinput && (
            <CustomInput
              className="InputCustom2"
              placeholder="Type or Upload"
              value={AddCertifile?.substring(
                AddCertifile?.lastIndexOf("/") + 1
              )}
              onBlur={(event) => {
                console.log("object");
                setBlurCheck({ ...BlurCheck, AddCertifile: true });
                validate();
              }}
              suffix={
                !spin.currentfile ? (
                  <CustomUpload
                    setUploadstate={setAccomplishment}
                    setspin={setspin}
                    accept={
                      ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                    uploadfrom={"MdsettingProcess"}
                    uploadkey={"AddCertifile"}
                    setcurrentfile={setcurrentfile}
                    EditResponse={EditResponse}
                    certification={certification}
                    setEditResponse={setEditResponse}
                    settingsDynamic
                    setAddCertifile={setAddCertifile}
                    uploaddata={Accomplishmentdata}
                    // email={email}
                  >
                  <Icon className="UPLOAD" type="cloud-upload"  />
                  </CustomUpload>
                ) : (
                  <Spin
                    className="AccountInfoSPIN"
                    spinning={spin.currentfile}
            
                  />
                )
              }
            />
          )}
        </div>
        <Icon type="plus-circle" theme="filled" onClick={handleAddfile} />
      </div>
      {BlurCheck["AddCertifile"] && errors.AddCertifile && (
        <p className="ERROR">
          {BlurCheck["AddCertifile"] && errors.AddCertifile}
        </p>
      )}
      <div className="Education-Accomplishments-Details">
        <h5>Professional Memberships</h5>
        <div className="Inputs1">
          {profMembership?.length > 0
            ? profMembership.map((item, i) => (
                <div className="Inner-Field-Details">
                  <TextArea
                    maxLength="120"
                    className="InputCustom1"
                    value={item}
                    name={item}
                    onChange={(e) => handleprofMembershipTextChange(i, e)}
                    autoSize={{ minRows: 2 }}
                  />
                  <Icon
                    type="minus-circle"
                    theme="filled"
                    onClick={() => handleRemovedItems(i, "ProMemberships")}
                  />
                  {RemovedProMembership &&
                    RemovedProMembership.map(
                      (item) =>
                        item === i && (
                          <p className="ERROR1">Field cant be blank</p>
                        )
                    )}
                </div>
              ))
            : !intialProMembershipInput && (
                <TextArea
                  maxLength="120"
                  placeholder="Enter your Professional Memberships"
                  value={AddProtext}
                  name="AddProtext"
                  onChange={handletextChange}
                  autoSize={{ minRows: 2 }}
                />
              )}

          {AddProinput && (
            <TextArea
              maxLength="120"
              className="TextAreaInput"
              placeholder="Enter your Professional Memberships"
              value={AddProtext}
              name="AddProtext"
              onChange={handletextChange}
              autoSize={{ minRows: 2 }}
              onBlur={(event) => {
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
            />
          )}
        </div>
        <Icon
          type="plus-circle"
          theme="filled"
          onClick={() => handleAddText("profMembership")}
        />
      </div>
      {BlurCheck["AddProtext"] && errors.AddProtext && (
        <p className="ERROR">{BlurCheck["AddProtext"] && errors.AddProtext}</p>
      )}
      <div className="Education-Accomplishments-Details">
        <h5>Awards</h5>
        <div className="Inputs1">
          {awards?.length > 0
            ? awards.map((item, i) => (
                <div className="Inner-Field-Details">
                  <TextArea
                    maxLength="120"
                    className="InputCustom1"
                    value={item}
                    name={item}
                    onChange={(e) => handleAwardsTextChange(i, e)}
                    autoSize={{ minRows: 2 }}
                  />
                  <Icon
                    type="minus-circle"
                    theme="filled"
                    onClick={() => handleRemovedItems(i, "Awards")}
                  />
                  {RemovedAwards &&
                    RemovedAwards.map(
                      (item) =>
                        item === i && (
                          <p className="ERROR1">Field cant be blank</p>
                        )
                    )}
                </div>
              ))
            : !intialAwardsInput && (
                <TextArea
                  maxLength="120"
                  placeholder="Enter your Awards"
                  value={AddAwardstext}
                  name="AddAwardstext"
                  onChange={handletextChange}
                  autoSize={{ minRows: 2 }}
                  onBlur={(event) => {
                    setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    validate();
                  }}
                />
              )}

          {AddAwardsinput && (
            <TextArea
              maxLength="120"
              className="TextAreaInput"
              placeholder="Enter your Awards"
              value={AddAwardstext}
              name="AddAwardstext"
              onChange={handletextChange}
              onBlur={(event) => {
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
              autoSize={{ minRows: 2 }}
            />
          )}
        </div>
        <Icon
          type="plus-circle"
          theme="filled"
          onClick={() => handleAddText("Awards")}
        />
      </div>
      {BlurCheck["AddAwardstext"] && errors.AddAwardstext && (
        <p className="ERROR">
          {BlurCheck["AddAwardstext"] && errors.AddAwardstext}
        </p>
      )}
    </>
  );
};

export default EditAccomplishment;
