import React, { useState, useEffect } from "react";
import { Icon, Input, Spin } from "antd";
import AutoComplete from "./../../../../Common/AutoComplete/AutoComplete";
import CustomInput from "./../../../../Common/custom-input";

const { TextArea } = Input;

const Education = ({
  setprofileinfo,
  Educationdata,
  setEducation,
  setLoading,
  Loading,
}) => {
  const [AddInternshipinput, setAddInternshipinput] = useState();
  const [AddInternshiptext, setAddInternshiptext] = useState();
  const [AddFellowshipinput, setAddFellowshipinput] = useState();
  const [AddFellowshiptext, setAddFellowshiptext] = useState();
  const [Removedinternship, setRemovedinternship] = useState([]);
  const [RemovedFellowship, setRemovedFellowship] = useState([]);
  const [intialInternInput, setintialInternInput] = useState(false);
  const [intialFellowsInput, setintialFellowsInput] = useState(false);
  const [enable, setenable] = useState(false);
  let [errors, setErrors] = useState({});

  useEffect(() => {
    if (
      medicalSchool &&
      intership?.length > 0 &&
      fellowship?.length > 0 &&
      residency &&
      Removedinternship.length === 0 &&
      RemovedFellowship.length === 0
    ) {
      setenable(true);
    } else {
      setenable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Educationdata]);

  let [BlurCheck, setBlurCheck] = useState({
    medicalSchool: false,
    AddInternshiptext: false,
    AddFellowshiptext: false,
    residency: false,
  });

  const { medicalSchool, intership, fellowship, residency } = Educationdata;

  let isValid = () => {
    let valid = true;
    //reading all controls from 'errors' state
    for (let control in errors) {
      if (errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  let validate = () => {
    let errorsData = {};
    //email can't blank
    if (!medicalSchool) {
      errorsData.medicalSchool = "medicalSchool can't be blank";
    }
    if (!AddInternshiptext && intership.length === 0) {
      errorsData.AddInternshiptext = "intership can't be blank";
    }
    if (AddInternshiptext && intership.length === 0) {
      errorsData.AddInternshiptext = "please add intership in list";
    }

    if (!residency) {
      errorsData.residency = "residency can't be blank";
    }

    if (!AddFellowshiptext && fellowship.length === 0) {
      errorsData.AddFellowshiptext = "Fellowship can't be blank";
    }
    if (AddFellowshiptext && fellowship.length === 0) {
      errorsData.AddFellowshiptext = "please add Fellowship in list";
    }
    setErrors(errorsData);
  };

  useEffect(validate, [Educationdata]);

  console.log("errors", errors);
  const handleGotoAccomplishment = () => {
    let BlurCheckData = BlurCheck;
    Object.keys(BlurCheck).forEach((control) => {
      BlurCheckData[control] = true;
    });
    setBlurCheck(BlurCheckData);
    validate();
    console.log("errrd.......", errors);
    if (
      isValid() &&
      Removedinternship.length === 0 &&
      RemovedFellowship.length === 0
    ) {
      setLoading(true);
      setintialInternInput(false);
      setintialFellowsInput(false);
      setTimeout(() => {
        setprofileinfo("Accomplishment");
        setLoading(false);
      }, 2000);
    }
  };

  const handletextChange = (e) => {
    const { name, value } = e.target;
    if (name === "AddInternshiptext") {
      setAddInternshiptext(value);
    } else if (name === "AddFellowshiptext") setAddFellowshiptext(value);
    else {
      setEducation({
        ...Educationdata,
        [name]: value,
      });
    }
  };

  const handleAddText = (val) => {
    if (val === "Internship") {
      if (intership.length > 0) {
        setAddInternshipinput(true);
        if (AddInternshiptext) {
          setAddInternshipinput(false);
          setEducation({
            ...Educationdata,
            intership: [...intership, AddInternshiptext],
          });
          setAddInternshiptext();
          setAddInternshipinput(true);
        }
      } else {
        if (AddInternshiptext) {
          setEducation({
            ...Educationdata,
            intership: [...intership, AddInternshiptext],
          });
          setAddInternshiptext();
          setAddInternshipinput(true);
        }
      }
    } else if (val === "Fellowship") {
      if (fellowship.length > 0) {
        setAddFellowshipinput(true);
        if (AddFellowshiptext) {
          setAddFellowshipinput(false);
          setEducation({
            ...Educationdata,
            fellowship: [...fellowship, AddFellowshiptext],
          });
          setAddFellowshiptext();
          setAddFellowshipinput(true);
        }
      } else {
        if (AddFellowshiptext) {
          setEducation({
            ...Educationdata,
            fellowship: [...fellowship, AddFellowshiptext],
          });
          setAddFellowshiptext();
          setAddFellowshipinput(true);
        }
      }
    }
  };

  const handleintershipTextChange = (i, event) => {
    console.log("event.target.value", i, event.target.value);
    let newArr = { ...Educationdata };
    newArr.intership[i] = event.target.value;
    setEducation(newArr);
    if (!newArr.intership[i]) {
      setRemovedinternship([...Removedinternship, i]);
    } else {
      const filter = Removedinternship.filter((item) => item !== i);
      setRemovedinternship(filter);
    }
  };

  const handlefellowshipTextChange = (i, event) => {
    console.log("event.target.value", i, event.target.value);
    let newArr = { ...Educationdata };
    newArr.fellowship[i] = event.target.value;
    setEducation(newArr);
    if (!newArr.fellowship[i]) {
      setRemovedFellowship([...RemovedFellowship, i]);
    } else {
      const filter = RemovedFellowship.filter((item) => item !== i);
      setRemovedFellowship(filter);
    }
  };

  const handleRemovedItems = (i, val) => {
    let newArr = { ...Educationdata };
    if (val === "internship") {
      const filter = newArr.intership.filter((item, index) => index !== i);
      setEducation((prevState) => ({
        ...prevState,
        intership: filter,
      }));
      console.log("filter", filter);
      setintialInternInput(true);
    } else if (val === "fellowship") {
      const filter = newArr.fellowship.filter((item, index) => index !== i);
      setEducation((prevState) => ({
        ...prevState,
        fellowship: filter,
      }));
      console.log("filter", filter);
      setintialFellowsInput(true);
    }
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 120 }} spin />;
  
  return (
    <div className="Education-Body">
      <h3>My Education</h3>
      <div className="Education-Body-Details">
        <h4 className="NoTextarea">Medical School</h4>
        <h4 className="NoTextarea">:</h4>
        <CustomInput
          className="InputCustom"
          placeholder="Enter your Medical school"
          value={medicalSchool}
          name="medicalSchool"
          onChange={handletextChange}
          onBlur={(event) => {
            console.log("object");
            setBlurCheck({ ...BlurCheck, [event.target.name]: true });
            validate();
          }}
        />
      </div>
      {BlurCheck["medicalSchool"] && errors.medicalSchool && (
        <p className="ERROR">
          {BlurCheck["medicalSchool"] && errors.medicalSchool}
        </p>
      )}

      <div className="Education-Body-Details">
        <h4>Internship</h4>
        <h4>:</h4>
        <div className="Textarea-Details">
          {intership.length > 0
            ? intership.map((item, i) => (
                <div className="Inner-Textarea-Details">
                  <TextArea
                    maxLength="120"
                    value={item}
                    name={item}
                    onChange={(e) => handleintershipTextChange(i, e)}
                    autoSize={{ minRows: 2 }}
                  />
                  <Icon
                    type="minus-circle"
                    theme="filled"
                    onClick={() => handleRemovedItems(i, "internship")}
                  />
                  {Removedinternship &&
                    Removedinternship.map(
                      (item) =>
                        item === i && (
                          <p className="ERROR1">Field cant be blank</p>
                        ),
                    )}
                </div>
              ))
            : !intialInternInput && (
                <TextArea
                  placeholder="Enter your Internship"
                  maxLength="120"
                  value={AddInternshiptext}
                  name="AddInternshiptext"
                  onChange={handletextChange}
                  autoSize={{ minRows: 2 }}
                  onBlur={(event) => {
                    console.log("object");
                    setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    validate();
                  }}
                />
              )}

          {AddInternshipinput && (
            <TextArea
              maxLength="120"
              className="TextAreaInput"
              placeholder="Enter your Internship"
              value={AddInternshiptext}
              name="AddInternshiptext"
              onChange={handletextChange}
              autoSize={{ minRows: 2 }}
              onBlur={(event) => {
                console.log("object");
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
            />
          )}
        </div>
        <Icon
          className="PLUS"
          type="plus-circle"
          theme="filled"
          onClick={() => handleAddText("Internship")}
        />
      </div>
      <Spin spinning={Loading} indicator={antIcon} />
      {BlurCheck["AddInternshiptext"] && errors.AddInternshiptext && (
        <p className="ERROR">
          {BlurCheck["AddInternshiptext"] && errors.AddInternshiptext}
        </p>
      )}

      <div className="Education-Body-Details">
        <h4 className="NoTextarea">Residency</h4>
        <h4 className="NoTextarea">:</h4>
        <div className="Location-Details">
          <AutoComplete
            placeholder="where's your Residency"
            comp="Education"
            setEducation={setEducation}
          />
        </div>
      </div>
      {BlurCheck["residency"] && errors.residency && (
        <p className="ERROR">{BlurCheck["residency"] && errors.residency}</p>
      )}

      <div className="Education-Body-Details">
        <h4>Fellowship</h4>
        <h4>:</h4>
        <div className="Textarea-Details">
          {fellowship.length > 0
            ? fellowship.map((item, i) => (
                <div className="Inner-Textarea-Details">
                  <TextArea
                    maxLength="120"
                    className="InputCustom1"
                    value={item}
                    name={item}
                    onChange={(e) => handlefellowshipTextChange(i, e)}
                    autoSize={{ minRows: 2 }}
                  />
                  <Icon
                    type="minus-circle"
                    theme="filled"
                    onClick={() => handleRemovedItems(i, "fellowship")}
                  />
                  {RemovedFellowship &&
                    RemovedFellowship.map(
                      (item) =>
                        item === i && (
                          <p className="ERROR1">Field cant be blank</p>
                        ),
                    )}
                </div>
              ))
            : !intialFellowsInput && (
                <TextArea
                  placeholder="Enter your Fellowship"
                  maxLength="120"
                  value={AddFellowshiptext}
                  name="AddFellowshiptext"
                  onChange={handletextChange}
                  autoSize={{ minRows: 2 }}
                  onBlur={(event) => {
                    console.log("object");
                    setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    validate();
                  }}
                />
              )}

          {AddFellowshipinput && (
            <TextArea
              maxLength="120"
              className="TextAreaInput"
              placeholder="Enter your Fellowship"
              value={AddFellowshiptext}
              name="AddFellowshiptext"
              onChange={handletextChange}
              autoSize={{ minRows: 2 }}
              onBlur={(event) => {
                console.log("object");
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
            />
          )}
        </div>
        <Icon
          className="PLUS"
          type="plus-circle"
          theme="filled"
          onClick={() => handleAddText("Fellowship")}
        />
      </div>
      {BlurCheck["AddFellowshiptext"] && errors.AddFellowshiptext && (
        <p className="ERROR">
          {BlurCheck["AddFellowshiptext"] && errors.AddFellowshiptext}
        </p>
      )}

      <div className="NextBTN">
        <img
          src={require("../../../../Assets/Arrowbutton.svg")}
          alt="btn1"
          onClick={handleGotoAccomplishment}
          style={{ opacity: enable ? 1 : 0.5 }}
        />
      </div>
    </div>
  );
};

export default Education;
