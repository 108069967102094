import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Icon,
  Button,
  Modal,
  DatePicker,
  Badge,
  Row,
  Col,
  Input,
  message,
  Spin,
} from "antd";
import {
  Appointment_Create_Action,
  getAppointments_Action,
  cancelAppointments_Action,
  getSchedule_Action,
  Appointment_Update_Action,
  cancelSingleAppointment_Action
} from "../../../Redux/CreateAppintment/CreateAppintmentActions";
import { useDispatch, useSelector } from "react-redux";
import "./DashboardBookings.scss";
import moment from "moment";
import { useHistory } from "react-router-dom";
import DashboardLayout from "./../DashboardLayout";
import {
  blockInvalidChar,
  PreviousdisabledDate,
  disableDateRanges,
} from "./../../..//utills/helpers";
import ConsultationOverview from "../MainDashBoard/Components/ConsultationOverview";
import GetlocalStorage from "../../../Common/localStorageHelper/getLocalStorage";
const { TextArea } = Input;

const WeekOffs = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
let DayOffs = [];

let morningTime = [];
let afternoonTime = [];
let eveningTime = [];

const DashboardBookings = () => {
  const [scheduledays, setScheduledays] = useState([]);
  const [slotBookingsModal, setslotBookingsModal] = useState(false);
  const [cancelBookingModal, setcancelBookingModal] = useState(false);
  const [cancelSingleBookingModal, setCancelSingleBookingModal] = useState(false);
  const [dayOff, setdayOff] = useState(DayOffs);
  const [dayOff1, setdayOff1] = useState([]);
  const [startTime, setStartTime] = useState("9");
  const [endTime, setEndTime] = useState("14");
  const [sessionTime, setSessionTime] = useState("45");
  const [breakTime, setBreakTime] = useState("15");
  const [repeat, setRepeat] = useState("MONTH");
  const [weekoffs, setWeekOffs] = useState(["Sun"]);
  const [timeSlots, setTimeSlotes] = useState([]);
  const [selectedSlots, setSelectedSlotes] = useState([]);
  const [morningSlots, setMorningSlots] = useState([]);
  const [afternoonSlots, setAfternoonSlots] = useState([]);
  const [evngSlots, setEvngSlots] = useState([]);
  const [errors, setErrors] = useState();
  const [Loading, setLoading] = useState(true);
  const [morningAppointments, setMorningAppointments] = useState([]);
  const [afternoonAppointments, setAfternoonAppointments] = useState([]);
  const [evngAppointments, setEvngAppointments] = useState([]);
  const [ids, setIds] = useState([]);
  const [bookingIds, setBookingIds] = useState([]);
  const [patientEmails, setPatientEmails] = useState([]);
  const [edit, setEdit] = useState(false);
  const [evgsub, setEvgsub] = useState(false);
  const [declainedReason, setDeclainedReason] = useState("");
  const [selectedweek, setselectedweek] = useState();
  const [sessionPrice, setsessionPrice] = useState(50);
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("MM-DD-YYYY"));
  const [currentDate, setCurrentDate] = useState(moment(new Date()).format("MMM-DD-YYYY"));
  const [selectedCurrentdate, setselectedCurrentdate] = useState(moment(new Date()).format("MM-DD-YYYY"));
  const [selectedCurrentdate1, setselectedCurrentdate1] = useState(moment(new Date()).format("MM-DD-YYYY"));
  const [cancle, setCancle] = useState(false);
  const [cancleItem, setCancleItem] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();

  let getlocalStorage = GetlocalStorage();
  let userId = getlocalStorage?.Doctorid;

  if (!window.location.hash) {
    window.location = window.location + "#loaded";
    window.location.reload();
  }

  useEffect(() => {
    var dt = new Date();

    // dt.getMonth() will return a month between 0 - 11
    // we add one to get to the last day of the month
    // so that when getDate() is called it will return the last day of the month
    var month = dt.getMonth() + 1;
    var year = dt.getFullYear();

    // this line does the magic (in collab with the lines above)
    var daysInMonth = new Date(year, month, 0).getDate();
    var startDate = new Date();
    var aryDates = GetDates(startDate, daysInMonth);
    setScheduledays(aryDates);
  }, []);

  /* cancle all bokkings then again call booking */
  useEffect(() => {
    setCancle(false);
    handleSlots1();
  }, [cancle]);

  const handleSlots1 = async (item) => {
    // if (cancle === true) {
    setLoading(true);
    let data = {
      date: moment(selectedCurrentdate1).format("YYYY-MM-DD"),
    };
    dispatch(getAppointments_Action(data, Routercall, handleLoader));
  };

  /* End */
  // useEffect(() => {
  //   let data = {  date:selectedDate, };
  //   setSelectedDate(moment(new Date()).format("MM-DD-YYYY"));
  //   console.log("selectedDatesss", selectedDate)
  //   dispatch(getAppointments_Action(data, Routercall, handleLoader));
  //   dispatch(getSchedule_Action(userId, Routercall, handleLoader));
  // }, [selectedDate]);

  useEffect(() => {
    if (selectedDate) {
      let data = { date: selectedDate };
      console.log("selectedDate", selectedDate);
      dispatch(getAppointments_Action(data, Routercall, handleLoader));
      dispatch(getSchedule_Action(userId, Routercall, handleLoader));
    }
  }, [selectedDate]);


  const handleSlots = async (item) => {
    setLoading(true);
    let date = moment(item.day).format("YYYY-MM-DD");
    await setSelectedDate(item.day);
    await setCurrentDate(moment(item.day).format("MM-DD-YYYY"));
    await setselectedCurrentdate(moment(item.day).format("MM-DD-YYYY"));
    await setselectedCurrentdate1(moment(item.day).format("MM-DD-YYYY"));
    let data = {
      date: date,
    };
    dispatch(getAppointments_Action(data, Routercall, handleLoader));
  };

  let appointmentsData = [];
  appointmentsData = useSelector((state) => state.Get_Appointment_Reducer);
  let scheduleData = useSelector((state) => state.Get_Schedule_Reducer);
  console.log("scheduleData", scheduleData);
  useEffect(() => {
    bookings();
  }, [appointmentsData]);

  useEffect(() => {
    console.log("scheduleData", typeof scheduleData);
    schedules();
  }, [Object.keys(scheduleData).length !== 0]);

  const schedules = () => {
    if (Object.keys(scheduleData).length !== 0) {
      const {
        totalSlots,
        selectedSlots,
        weekOffs,
        dayOffs,
        startTime,
        endTime,
        breakTime,
        sessionTime,
        repeatSlot,
        slotPrice,
        morningSlots,
        afternoonSlots,
        evngSlots,
      } = scheduleData;
      setsessionPrice(slotPrice);
      setTimeSlotes(totalSlots.slots);
      setSelectedSlotes(selectedSlots.slots);
      setEndTime(endTime);
      setStartTime(startTime);
      setWeekOffs(weekOffs);
      setSessionTime(sessionTime);
      setBreakTime(breakTime);
      setRepeat(repeatSlot);
      setdayOff(dayOffs);
      setdayOff1(dayOffs);
      setMorningSlots(morningSlots);
      setAfternoonSlots(afternoonSlots);
      setEvngSlots(evngSlots);
      setEdit(true);
      dayOffs.map((item) => {
        DayOffs.push(moment(item).format("MM-DD-YYYY"));
      });
      //getSlots(startTime, endTime, sessionTime, breakTime);
    }
  };

  const bookings = () => {
    let ids = [], bookingIds = [], patientEmails = [];
    if (appointmentsData && appointmentsData.length > 0) {
      appointmentsData.map((item, index) => {
        ids.push(item._id);
        bookingIds.push(item.bookingId);
        patientEmails.push(item.patientEmail);
        getBookedSlots(item.appointmentSlotTime, item.appointmentSlot, item._id, item.bookingId, item.patientEmail);
      });
      setIds(ids);
      setPatientEmails(patientEmails);
      setBookingIds(bookingIds);
    } else {
      setMorningAppointments([]);
      setAfternoonAppointments([]);
      setEvngAppointments([]);
    }
  };

  var morningSlots2 = [],
    evngSlots1 = [],
    afternoonSlots1 = [];

  const getBookedSlots = (appointmentSlotTime, appointmentSlot, _id, bookinId, patientEmail) => {
    var startTime1 = moment(appointmentSlotTime, "HH:mm");
    var morning = moment("12", "HH:mm");
    var afternoon = moment("17", "HH:mm");
    if (
      moment(morning).format("HH:mm") >= new moment(startTime1).format("HH:mm")
    ) {
      morningSlots2.push({ appointmentSlot: appointmentSlot, _id: _id, bookinId: bookinId, email: patientEmail });
    } else if (
      moment(afternoon).format("HH:mm") > moment(startTime1).format("HH:mm")
    ) {
      afternoonSlots1.push({ appointmentSlot: appointmentSlot, _id: _id, bookinId: bookinId, email: patientEmail });
    } else if (
      moment(afternoon).format("HH:mm") <= moment(startTime1).format("HH:mm")
    ) {
      evngSlots1.push({ appointmentSlot: appointmentSlot, _id: _id, bookinId: bookinId, email: patientEmail });
    }
    setMorningAppointments(morningSlots2);
    setAfternoonAppointments(afternoonSlots1);
    setEvngAppointments(evngSlots1);
  };

  const GetDates = (startDate, daysToAdd) => {
    var aryDates = [];
    for (var i = 0; i <= daysToAdd; i++) {
      var currentDate = new Date();
      currentDate.setDate(startDate.getDate() + i);
      aryDates.push({
        day: new Date(currentDate),
        date: new Date(currentDate),
      });
    }
    return aryDates;
  };

  useEffect(() => {
    getSlots(startTime, endTime, sessionTime, breakTime);
    //return timeStops;
  }, [startTime, endTime, sessionTime, breakTime]);

  const getSlots = () => {
    var startTime1 = moment(startTime, "HH:mm");
    var endTime1 = moment(endTime, "HH:mm");
    var morning = moment("12", "HH:mm");
    var afternoon = moment("17", "HH:mm");
    var timeStops = [];
    var morningSlots = [],
      evngSlots = [],
      afternoonSlots = [];
    let evg = 0;
    while (startTime1 <= endTime1) {
      if (
        moment(morning).format("HH:mm") > new moment(startTime1).format("HH:mm")
      ) {
        if (
          new moment(endTime1).format("HH:mm") >
          new moment(startTime1).format("HH:mm")
        ) {
          morningSlots.push(
            new moment(startTime1).format("HH:mm") +
            "-" +
            new moment(startTime1.add(Number(sessionTime), "minutes")).format(
              "HH:mm"
            )
          );
        }
      } else if (
        moment(afternoon).format("HH:mm") > moment(startTime1).format("HH:mm")
      ) {
        if (
          new moment(endTime1).format("HH:mm") >
          new moment(startTime1).format("HH:mm")
        ) {
          afternoonSlots.push(
            moment(startTime1).format("HH:mm") +
            "-" +
            new moment(startTime1.add(Number(sessionTime), "minutes")).format(
              "HH:mm"
            )
          );
        }
      } else if (
        moment(afternoon).format("HH:mm") < moment(startTime1).format("HH:mm")
      ) {
        if (evg == 0) {
          startTime1.subtract(Number(breakTime), "minutes");
          evg = 1;
        }
        if (evg == 1 && sessionTime != 45) {
          startTime1.add(Number(breakTime), "minutes");
          evg = 0;
        }

        if (
          new moment(endTime1).format("HH:mm") >
          new moment(startTime1).format("HH:mm")
        ) {
          evngSlots.push(
            new moment(startTime1).format("HH:mm") +
            "-" +
            new moment(startTime1.add(Number(sessionTime), "minutes")).format(
              "HH:mm"
            )
          );
        }
      }
      startTime1.add(Number(breakTime), "minutes");
    }
    timeStops = morningSlots + afternoonSlots + evngSlots;
    setTimeSlotes(timeStops);
    morningTime = morningSlots;
    afternoonTime = afternoonSlots;
    eveningTime = evngSlots;
  };

  const handleStartTime = async (e, value) => {
    setSelectedSlotes([]);
    setMorningSlots([]);
    setAfternoonSlots([]);
    setEvngSlots([]);
    setErrors("");
    if (value === "startTime")
      await setStartTime(e.target.value.substring(0, 2));
    if (value === "endTime") await setEndTime(e.target.value.substring(0, 2));
    if (value === "sessionTime")
      await setSessionTime(e.target.value.substring(0, 2));
    if (value === "breakTime")
      await setBreakTime(e.target.value.substring(0, 2));
    if (value === "SessionPrice")
      await setsessionPrice(e.target.value.substring(0, 3));
  };

  const myRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scroll = (scrollOffset) => {
    myRef.current.scrollLeft += scrollOffset;
  };

  const Slot_Date = (e) => {
    setErrors("");
    let DayOffs1 = [];
    console.log("eeee", e._id, moment(e).format("MM-DD-YYYY"));
    let Dateslot = moment(moment(e).format("MM-DD-YYYY"))._i;
    if (DayOffs.includes(moment(e).format("MM-DD-YYYY"))) {
      message.error("Already selected");
    } else {
      DayOffs1.push(moment(e).format("MM-DD-YYYY"));
      setdayOff(DayOffs.push(Dateslot));
      setdayOff1(DayOffs1);
    }
  };

  const removeSlotDate = (slot) => {
    let index = DayOffs.indexOf(slot);
    if (index > -1) {
      DayOffs.splice(index, 1);
      var filtered = DayOffs.filter(function (value) {
        return value !== slot;
      });
      setdayOff(filtered);
      setdayOff1(filtered);
    }
  };

  const handleWeekOff = useCallback(
    (i) => {
      setSelectedSlotes([]);
      setMorningSlots([]);
      setAfternoonSlots([]);
      setEvngSlots([]);
      if (weekoffs.includes(i)) {
        const filter = weekoffs.filter((item) => item !== i);
        setWeekOffs(filter);
      } else {
        const add = [...weekoffs];
        add.push(i);
        setWeekOffs(add);
      }
      console.log("weekoffs", weekoffs);
    },
    [weekoffs]
  );

  useEffect(() => {
    setWeekOffs(weekoffs);
  }, [weekoffs]);

  const handleSelectedMorningSlots = useCallback(
    (i) => {
      if (selectedSlots.includes(i) && morningSlots.includes(i)) {
        const filter = selectedSlots.filter((item) => item !== i);
        setSelectedSlotes(filter);
        const filter1 = morningSlots.filter((item) => item !== i);
        setMorningSlots(filter1);
      } else {
        const add = [...selectedSlots];
        const add1 = [...morningSlots];
        add.push(i);
        add1.push(i);
        setSelectedSlotes(add);
        setMorningSlots(add1);
      }
    },
    [selectedSlots]
  );

  const handleSelectedAfternoonSlots = useCallback(
    (i) => {
      if (selectedSlots.includes(i)) {
        const filter = selectedSlots.filter((item) => item !== i);
        setSelectedSlotes(filter);
        const filter1 = afternoonSlots.filter((item) => item !== i);
        setAfternoonSlots(filter1);
      } else {
        const add = [...selectedSlots];
        const add1 = [...afternoonSlots];
        add.push(i);
        add1.push(i);
        setSelectedSlotes(add);
        setAfternoonSlots(add1);
      }
    },
    [selectedSlots]
  );

  const handleSelectedEvngSlots = useCallback(
    (i) => {
      if (selectedSlots.includes(i)) {
        const filter = selectedSlots.filter((item) => item !== i);
        setSelectedSlotes(filter);
        const filter1 = evngSlots.filter((item) => item !== i);
        setEvngSlots(filter1);
      } else {
        const add = [...selectedSlots];
        const add1 = [...evngSlots];
        add.push(i);
        add1.push(i);
        setSelectedSlotes(add);
        setEvngSlots(add1);
      }
    },
    [selectedSlots]
  );

  const handleSave = () => {
    let errors = {};
    if (selectedSlots.length === 0)
      errors.selectedSlots = "*selectedSlots Required";
    if (timeSlots.length === 0) errors.timeSlots = "*timeSlots Required";
    //if (weekoffs.length === 0) errors.weekOffs = "*weekOffs Required";
    //if (dayOff.length === 0) errors.dayOff = "*dayOff Required";
    if (startTime.trim() === "") errors.startTime = "*startTime Required";
    if (endTime.trim() === "") errors.endTime = "*endTime Required";
    if (breakTime === "") errors.breakTime = "*breakTime Required";
    if (sessionTime === "") errors.sessionTime = "*sessionTime Required";
    if (sessionPrice === "") errors.sessionPrice = "*sessionPrice Required";
    setErrors(errors);
    if (startTime > 23) {
      return message.error("Max start Time should be 23");
    } else if (endTime > 24) {
      return message.error("Max End Time should be 24");
    }

    if (Object.keys(errors).length == 0) {
      let data = {
        totalSlots: timeSlots,
        selectedSlots: [...new Set(selectedSlots)],
        weekOffs: [...new Set(weekoffs)],
        userId: getlocalStorage?.Doctorid,
        startTime: startTime,
        endTime: endTime,
        breakTime: breakTime,
        sessionTime: sessionTime,
        repeatSlot: repeat,
        dayOffs: [...new Set(dayOff1)],
        morningSlots: [...new Set(morningSlots)],
        afternoonSlots: [...new Set(afternoonSlots)],
        evngSlots: [...new Set(evngSlots)],
        slotPrice: Number(sessionPrice),
      };

      if (edit) {
        dispatch(
          Appointment_Update_Action(data, userId, Routercall, handleLoader)
        );
      } else {
        dispatch(Appointment_Create_Action(data, Routercall, handleLoader));
      }
    } else {
      message.error("Something went wrong");
    }
    console.log("errors", errors);
  };

  const Routercall = (val) => {
    if (val) {
      message.error(val);
    } else {
      setslotBookingsModal(false);
    }
  };

  const handleLoader = (val) => {
    setLoading(val);
  };

  const handleModel = (val) => {
    setDeclainedReason("")
    setcancelBookingModal(val);
  };
  const handleSingleCancleModel = (val) => {
    setDeclainedReason("")
    setCancelSingleBookingModal(val);
  };

  const handleCancelSigleBooking = async () => {

    let data = cancleItem;
    data.declainedReason = declainedReason;
    dispatch(
      await cancelSingleAppointment_Action(data, Routercall, handleLoader, handleSingleCancleModel, handleSlots1)
    );
  }

  const handleCancel = () => {
    setCancle(true);
    const data = {
      bookingIds: bookingIds,
      emails: patientEmails,
      ids: ids,
      isDeclained: true,
      declainedReason: declainedReason,
      isAccepted: false,
    };
    dispatch(
      cancelAppointments_Action(data, Routercall, handleLoader, handleModel)
    );
  };
  const handlesoltCancel = (i) => {
    console.log("i", i);
    setCancelSingleBookingModal(true);
    // setcancelBookingModal(true);
    const data = {
      _id: i._id,
      isDeclained: true,
      declainedReason: declainedReason,
      isAccepted: false,
      email: i.email,
      bookingId: i.bookinId
    };
    setCancleItem(data)
  };
  const handleReason = (e) => {
    setDeclainedReason(e.target.value);
  };

  const antIcon = <Icon type="loading" spin />;
  const fullName = getlocalStorage?.fullName;
  console.log("morningAppointments", morningAppointments)
  return (
    <DashboardLayout>
      {/* <Spin spinning={Loading} indicator={antIcon} /> */}
      <div
        className="DashboardBookings-Body"
        style={{ opacity: Loading && "0.5" }}
      >
        <div className="scdules_header">
          <h3>Schedules</h3>
          <div className="DashboardBookings-ProfileInfo">
            <Icon
              style={{ visibility: "hidden" }}
              type="caret-down"
              theme="filled"
            />
            <h3 onClick={() => history.push("/dashboardsettings/accountinfo")}>
              {fullName}
            </h3>
            <Badge offset={[-10, 8]} color={"#2d80b5"} dot>
              <Icon
                onClick={() => history.push("/dashboardsettings/notifications")}
                type="bell"
                theme="filled"
              />
            </Badge>
          </div>
        </div>
        <div className="DashboardBookings-Filters">
          {/* <div className="DashboardBookings-Calendar">
            <MonthPicker defaultValue={moment('Feb 2021', 'MMM YYYY')} placeholder="Select month" />
          </div> */}
          <h3>Schedules of <span>{currentDate}</span></h3>
          <Button onClick={() => setslotBookingsModal(true)}>ADD</Button>

        </div>

        <div className="DashboardBookings-Body-Slots">
          <div className="DashboardBookings-Content-Date">
            <img
              className="Prevbutton"
              onClick={() => scroll(-1000)}
              src={require("../../../Assets/Prevbutton.png")}
              alt="icon1"
            />
            <div className="DashboardBookings-Slot-Dates" ref={myRef}>
              {scheduledays.map((item, i) => (
                <div className="DateTime" onClick={() => handleSlots(item)} key={i}>
                  <h3
                    style={{
                      opacity:
                        moment(item.day).format("MM-DD-YYYY") ===
                          selectedCurrentdate
                          ? 1
                          : 0.5,
                    }}
                  >
                    {moment(item.day).format("DD")}
                  </h3>
                  <div className="DAY">
                    <p
                      style={{
                        opacity:
                          moment(item.day).format("MM-DD-YYYY") ===
                            selectedCurrentdate
                            ? 1
                            : 0.5,
                      }}
                    >
                      {moment(item.day).format("ddd")}
                    </p>
                    <p
                      style={{
                        opacity:
                          moment(item.day).format("MM-DD-YYYY") ===
                            selectedCurrentdate
                            ? 1
                            : 0.5,
                      }}
                    >
                      {moment(item.day).format("MMM")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <Spin spinning={Loading} indicator={antIcon} />
            <img
              className="Nextbutton"
              onClick={() => scroll(1000)}
              src={require("../../../Assets/Nextbutton.png")}
              alt="icon1"
            />
          </div>
          <div className="DashboardBookings-Content-DayTime">
            <img src={require("../../../Assets/TH_morning.svg")} alt="icon1" />
            {morningAppointments.length !== 0 ? (
              <div
                className="Book-Details-Slots"
                style={
                  morningAppointments.length > 10
                    ? {}
                    : { alignSelf: "center", paddingTop: "0" }
                }
              >
                {morningAppointments.map((i) => (
                  <Button
                    className="SlotBTN"
                    onClick={() => handlesoltCancel(i)}
                  >
                    {i.appointmentSlot}
                  </Button>
                ))}
              </div>
            ) : (
              <h5>No Slots Booked</h5>
            )}
          </div>

          <div className="DashboardBookings-Content-DayTime">
            <img
              src={require("../../../Assets/TH_afternoon.svg")}
              alt="icon1"
            />
            {afternoonAppointments.length !== 0 ? (
              <div
                className="Book-Details-Slots"
                style={
                  afternoonAppointments.length > 10
                    ? {}
                    : { alignSelf: "center", paddingTop: "0" }
                }
              >
                {afternoonAppointments.map((i) => (
                  <Button
                    className="SlotBTN"
                    onClick={() => handlesoltCancel(i)}
                  >
                    {i.appointmentSlot}
                  </Button>
                ))}
              </div>
            ) : (
              <h5>No Slots Booked</h5>
            )}
          </div>
          <div className="DashboardBookings-Content-DayTime">
            <img src={require("../../../Assets/TH_evening.svg")} alt="icon1" />
            {evngAppointments.length !== 0 ? (
              <div
                className="Book-Details-Slots"
                style={
                  evngAppointments.length > 10
                    ? {}
                    : { alignSelf: "center", paddingTop: "0" }
                }
              >
                {evngAppointments.map((i) => (
                  <Button
                    className="SlotBTN"
                    onClick={() => handlesoltCancel(i)}
                  >
                    {i.appointmentSlot}
                  </Button>
                ))}
              </div>
            ) : (
              <h5>No Slots Booked</h5>
            )}
          </div>
          <div className="CancelBookingBTN">
            <Button
              onClick={() => setcancelBookingModal(true)}
              type="primary"
              disabled={
                moment().format("MM-DD-YYYY") === selectedCurrentdate ||
                  appointmentsData.length === 0
                  ? true
                  : false
              }
              style={
                moment().format("MM-DD-YYYY") === selectedCurrentdate
                  ? { opacity: 0.5 }
                  : {}
              }
            >
              Cancel All Bookings
            </Button>
          </div>
        </div>

        <Modal
          className="DashboardBookings-Modal"
          visible={slotBookingsModal}
          centered={true}
          footer={null}
          onCancel={() => setslotBookingsModal(false)}
          closeIcon={
            <Icon
              type="close"
              style={{
                color: "#4fb8b6",
              }}
            />
          }
        >
          <div className="DashboardBookings-Modal-Body">
            <h1> This {repeat}</h1>
            <Row>
              <Col lg={14}>
                <div className="DashboardBookings-Slot-Details">
                  <img
                    src={require("../../../Assets/calendar2.png")}
                    alt="icon1"
                  />
                  <h5>Choose Timings :</h5>
                  <Input
                    className="Choose-Time"
                    name="startTime"
                    value={startTime}
                    onChange={(e) => handleStartTime(e, "startTime")}
                    onKeyDown={blockInvalidChar}
                    type="number"
                    min={1}
                    max={2}
                  />
                  <h5 className="TO">to</h5>
                  <Input
                    className="Choose-Time"
                    name="endTime"
                    value={endTime}
                    onChange={(e) => handleStartTime(e, "endTime")}
                    onKeyDown={blockInvalidChar}
                    type="number"
                    maxlength="2"
                  />
                </div>
                {errors && (
                  <p className="Errors">
                    {errors ? errors.startTime || errors.endTime : ""}
                  </p>
                )}

                <div className="DashboardBookings-Slot-Details">
                  <Icon type="reload" rotate={180} />
                  <h5>Repeat slot :</h5>
                  <Button
                    className="SlotRepeatBtn"
                    style={{
                      backgroundColor: repeat === "WEEK" && "#A6DDD8",
                    }}
                    onClick={() => setRepeat("WEEK")}
                  >
                    This Week
                  </Button>
                  <Button
                    className="SlotRepeatBtn"
                    style={{
                      backgroundColor: repeat === "MONTH" && "#A6DDD8",
                    }}
                    onClick={() => setRepeat("MONTH")}
                  >
                    This Month
                  </Button>
                </div>
                <div className="DashboardBookings-Slot-Details">
                  <img
                    src={require("../../../Assets/dayoff.png")}
                    alt="icon1"
                  />
                  <h5>Week Offs :</h5>
                  <p style={{ color: "red" }}>
                    {errors ? errors.weekoffs : ""}
                  </p>
                  {WeekOffs.map((i, index) => (
                    <Button
                      onClick={() => {
                        handleWeekOff(i);
                      }}
                      className="WeekOffBtn"
                      shape="circle"
                      style={{
                        backgroundColor: weekoffs?.includes(i) && "#A6DDD8",
                      }}
                      key={index}
                    >
                      {i}
                    </Button>
                  ))}
                </div>
              </Col>
              <Col lg={10}>
                <div className="DashboardBookings-Slot-Details">
                  <img
                    src={require("../../../Assets/sessiontime.png")}
                    alt="icon1"
                  />
                  <h5>Session Time :</h5>
                  <Input
                    className="Session-BreakTime"
                    name="sessionTime"
                    value={sessionTime}
                    onChange={(e) => handleStartTime(e, "sessionTime")}
                  />
                  <p>Minutes</p>
                </div>
                {errors && (
                  <p className="Errors Error2">
                    {errors ? errors.sessionTime : ""}
                  </p>
                )}

                <div className="DashboardBookings-Slot-Details">
                  <img
                    src={require("../../../Assets/breaktime.png")}
                    alt="icon1"
                  />
                  <h5>Break Time :</h5>
                  <Input
                    className="Session-BreakTime"
                    name="breakTime"
                    value={breakTime}
                    onChange={(e) => handleStartTime(e, "breakTime")}
                  />
                  <p>Minutes</p>
                </div>
                {errors && (
                  <p className="Errors Error2">
                    {errors ? errors.breakTime : ""}
                  </p>
                )}
                <br />
                <div className="DashboardBookings-Slot-Details">
                  <Icon type="dollar" />
                  <h5>SessionPrice :</h5>
                  <Input
                    className="Choose-Time"
                    name="SessionPrice"
                    value={sessionPrice}
                    onChange={(e) => handleStartTime(e, "SessionPrice")}
                  />
                  {/* <p>/session</p> */}
                </div>
                {errors && (
                  <p className="Errors Error2">
                    {errors ? errors.sessionPrice : ""}
                  </p>
                )}
              </Col>
            </Row>
            <Spin spinning={Loading} indicator={antIcon} />
            <div className="DashboardBookings-Slot-Details">
              <img src={require("../../../Assets/dayoff.png")} alt="icon1" />
              <h5>Day Offs :</h5>
              <div className="Dayoffs-Calendar">
                {[...new Set(DayOffs)].map((i, index) => (
                  <p key={index}>
                    {i} <Icon onClick={() => removeSlotDate(i)} type="close" />
                  </p>
                ))}
                <DatePicker
                  onChange={Slot_Date}
                  value=""
                  placeholder="Select"
                  format="MM-DD-YYYY"
                  disabledDate={
                    repeat === "WEEK"
                      ? disableDateRanges({
                        endDate: moment().endOf("week"),
                        startDate: moment().startOf("week"),
                      })
                      : PreviousdisabledDate
                  }
                />
              </div>
            </div>
            {errors && (
              <p className="Errors Error1">{errors ? errors.dayOff : ""}</p>
            )}

            <div className="DashboardBookings-Slot-Timings">
              {errors && (
                <p className="Errors">{errors ? errors.selectedSlots : ""}</p>
              )}
              <div className="Book-Slots-Content">
                <div className="BookSlotIMG">
                  <img
                    src={require("../../../Assets/TH_morning.svg")}
                    alt="icon1"
                  />
                </div>
                <div className="Book-SlotTimings">
                  {morningTime && morningTime.length > 0
                    ? morningTime.map((i, index) => (
                      <Button
                        className="SlotBtn"
                        onClick={() => handleSelectedMorningSlots(i)}
                        style={
                          selectedSlots.includes(i)
                            ? { backgroundColor: "#A6DDD8" }
                            : {}
                        }
                        key={index}
                      >
                        {i}
                      </Button>
                    ))
                    : ""}
                </div>
              </div>
              <div className="Book-Slots-Content">
                <div className="BookSlotIMG">
                  <img
                    src={require("../../../Assets/TH_afternoon.svg")}
                    alt="icon1"
                  />
                </div>
                <div className="Book-SlotTimings">
                  {afternoonTime && afternoonTime.length > 0
                    ? afternoonTime.map((i, index) => (
                      <Button
                        className="SlotBtn"
                        onClick={() => handleSelectedAfternoonSlots(i)}
                        style={
                          selectedSlots.includes(i)
                            ? { backgroundColor: "#A6DDD8" }
                            : {}
                        }
                        key={index}
                      >
                        {i}
                      </Button>
                    ))
                    : ""}
                </div>
              </div>
              <div className="Book-Slots-Content">
                <div className="BookSlotIMG">
                  <img
                    src={require("../../../Assets/TH_evening.svg")}
                    alt="icon1"
                  />
                </div>
                <div className="Book-SlotTimings">
                  {eveningTime && eveningTime.length > 0
                    ? eveningTime.map((i, index) => (
                      <Button
                        className="SlotBtn"
                        onClick={() => handleSelectedEvngSlots(i)}
                        style={
                          selectedSlots.includes(i)
                            ? { backgroundColor: "#A6DDD8" }
                            : {}
                        }
                        key={index}
                      >
                        {i}
                      </Button>
                    ))
                    : ""}
                </div>
              </div>
            </div>
            <div className="DashboardBookings-Slot-Btns">
              <Button
                className="SaveBtn"
                type="primary"
                onClick={() => handleSave()}
              >
                Save
              </Button>
              <Button
                onClick={() => setslotBookingsModal(false)}
                className="CancelBtn"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          className="CancelBooking-Modal"
          visible={cancelBookingModal}
          centered={true}
          footer={null}
          onCancel={() => setcancelBookingModal(false)}
          closable={false}
        >

          <div className="CancelBooking-Modal-Body">
            <div className="CancelBooking-Modal-Body-Title">
              <h4>
                Do you want to cancel all bookings for{" "}
                {moment(selectedDate).format("MM-DD-YYYY")}
              </h4>
            </div>
            <TextArea
              placeholder="Type your issue to cancel here"
              autoSize={{ minRows: 6 }}
              value={declainedReason}
              onChange={(e) => handleReason(e)}
            />
          </div>
          <div className="CancelBooking-Btns">
            <Button
              className="CancelBTN"
              type="primary"
              onClick={() => handleCancel()}
            >
              Yes, cancel
            </Button>
            <Button
              className="ExitBTN"
              //type="primary"
              onClick={() => setcancelBookingModal(false)}
            >
              No, Exit
            </Button>
          </div>
        </Modal>
        <Modal
          className="CancelBooking-Modal"
          visible={cancelSingleBookingModal}
          centered={true}
          footer={null}
          onCancel={() => setCancelSingleBookingModal(false)}
          closable={false}
        >
          <Icon
            onClick={() => setCancelSingleBookingModal(false)}
            className="IconClose"
            type="close-circle"
            theme="filled"
          />
          <div className="CancelBooking-Modal-Body">
            <div className="CancelBooking-Modal-Body-Title">
              <h4>
                Do you want to cancel Appointment for{" "}
                {moment(selectedDate).format("MM-DD-YYYY")}
              </h4>
            </div>
            <TextArea
              placeholder="Type your issue to cancel here"
              autoSize={{ minRows: 6 }}
              value={declainedReason}
              onChange={(e) => handleReason(e)}
            />
          </div>
          <div className="CancelBooking-Btns">
            <Button
              className="CancelBTN"
              type="primary"
              onClick={() => handleCancelSigleBooking()}
            >
              Yes, cancel
            </Button>
            <Button
              className="ExitBTN"
              //type="primary"
              onClick={() => setCancelSingleBookingModal(false)}
            >
              No, Exit
            </Button>
          </div>
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default DashboardBookings;
